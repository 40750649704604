import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { closeSvg } from '../../img';

const CustomToaster: React.FC = () => {
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button
                  onClick={() => toast.dismiss(t.id)}
                  style={{ background: 'transparent', cursor: 'pointer' }}
                >
                  <img src={closeSvg} alt="" />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default CustomToaster;
