import { Portal } from 'react-portal';
import { makeStyles } from '@material-ui/core/styles';
import Plyr from 'plyr';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store/types';
import { setCurrentTime, setPause } from '../../store/videoSlice';
import getCurrentFrameId from '../../utils/getCurrentFrameId';
import {
  faceWithPreviousFrameEmotionsOnly,
  faceWithPreviousFrameEmotionsOnlyPts,
  findFrameData,
} from '../../utils/handleStatistics';
import { getFullVideoSrc } from '../../utils/player/getFullVideoSrc';
import { Data, ObjectData } from '../PulseChart/types';
import VideoItem from '../VideoItem';
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    '& .plyr': {
      borderRadius: 10,
    },
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  containerFullscreen: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  video: {
    left: 0,
    top: 0,
    position: 'relative',
    zIndex: 1000,
    width: '100%',
    height: '100%',
  },
  checkboxVisualize: {
    margin: '3px',
    padding: '7px 2px 7px 2px',
    lineHeight: '12px',
  }
}));

type Props = {
  controls?: ['Controls'];
  isLoading?: boolean;
  isUserManager?: boolean;
};

export const ScaleSecPts = 100 * 1000;
const FrameActualSec = 5;
const ActualSecPts = FrameActualSec * ScaleSecPts;

const VideoPlayer = ({ controls, isLoading, isUserManager }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { src, currentTime, fps, data, dataMeanSincerity, currentFrame, videoWidth, videoHeight } =
    useSelector((state: StoreState) => ({
      src: state.video.src,
      currentTime: state.video.currentTime,
      fps: state.video.fps,
      data: state.data.data,
      dataMeanSincerity: state.data.dataMeanSincerity,
      currentFrame: state.data.currentFrame,
      videoWidth: state.video.videoWidth,
      videoHeight: state.video.videoHeight,
    }));

  const containerRef = React.useRef(null);
  const videoElement = React.createRef<HTMLVideoElement>();
  const player = React.useRef<Plyr | null>(null);
  const [currentSource, setCurrentSource] = React.useState(null);

  const fullscreenActive = player.current?.fullscreen.active || false;

  // is from camera
  // const dataObjectPts = data?.find((e) => e != null && !!e.pts);
  // const isDataFromCamera = !!dataObjectPts;
  const isDataFromCamera = false;

  const [showParamsAll, setShowParamsAll] = React.useState(!isUserManager);

  let currentFrameId = -1;
  let dataObject: Data = { frame_id: -1, pts: null, Objects: [], violence: { percent: 0 } };
  if (isDataFromCamera) {
    const timeSecPts = currentTime * ScaleSecPts;
    // start from the end
    const dataObj = [...data]
      .reverse()
      .find((e) => e && e.pts <= timeSecPts && timeSecPts - e.pts < ActualSecPts);
    if (dataObj) {
      currentFrameId = dataObj.frame_id;
      dataObject = dataObj;
    }
  } else {
    currentFrameId = getCurrentFrameId(currentTime, currentFrame, fps);
    dataObject = { ...data.find((e) => e != null && e.frame_id === currentFrameId) };
  }
  // update data each 2 seconds (not each frame)
  const KEEP_VALUE_SECONDS = 2;
  const KEEP_VALUE_FRAMES = Math.round(KEEP_VALUE_SECONDS * (fps || 30));
  const prevSecondFrameId = KEEP_VALUE_FRAMES * Math.floor(dataObject.frame_id / KEEP_VALUE_FRAMES);
  const prevSecondFrameData = findFrameData(data, dataObject, prevSecondFrameId, 10);
  // set prev frame face values ignoring meta data
  if (dataObject) {
    dataObject = {
      ...dataObject,
      Objects: dataObject?.Objects?.map((e) => {
        const faceData: any = prevSecondFrameData?.Objects?.find((f) => f.uid === e?.uid);
        if (!!faceData)
          return {
            ...e,
            hr: faceData.hr,
            rr: faceData.rr,
            vi: faceData.vi,
            gazes: faceData.gazes,
            sincerity: faceData.sincerity,
            bp: faceData.bp,
          }
        return e;
      }),
    }
  }
  // fill emotions if need
  if (dataObject) {
    dataObject = {
      ...dataObject,
      Objects: dataObject?.Objects?.map((e) => {
        if (e && e.emotions && (!e.emotions || e.emotions.length === 0))
          return {
            ...e,
            emotions: isDataFromCamera
              ? faceWithPreviousFrameEmotionsOnlyPts(data, dataObject.frame_id, e, 30, ScaleSecPts)
              : faceWithPreviousFrameEmotionsOnly(data, dataObject.frame_id, e, fps * 30),
          };
        return e;
      }),
    };
  }

  const [containerWidthHere, setContainerWidthHere] = React.useState(0);
  const [containerHeightHere, setContainerHeightHere] = React.useState(0);

  const [widnowSize, setWindowSize] = React.useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // keep container width height on window resize
  React.useEffect(() => {
    if (containerRef.current && containerWidthHere !== containerRef.current.clientWidth)
      setContainerWidthHere(containerRef.current.clientWidth);
    if (containerRef.current && containerHeightHere !== containerRef.current.clientHeight)
      setContainerHeightHere(containerRef.current.clientHeight);
  }, [containerHeightHere, containerRef, containerWidthHere, widnowSize]);

  // apply actual container width height after a second using resize
  React.useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1500);
  }, []);

  const onCurrentTime = useCallback(
    (time: number) => {
      dispatch(setCurrentTime(time));
    },
    [dispatch]
  );

  const onSetPause = useCallback(
    (bool: boolean) => {
      dispatch(setPause(bool));
    },
    [dispatch]
  );

  useEffect(() => {
    if (src && src !== currentSource && !isLoading) {
      player.current = new Plyr(videoElement.current, {
        controls,
        autoplay: true,
        storage: {
          enabled: false,
        },
      });

      player.current.quality = 720;

      player.current.source = {
        type: 'video',
        sources: [
          {
            src: getFullVideoSrc(src),
            type: 'video/webm',
            size: 720,
          },
        ],
      };
      player.current.on('timeupdate', (event) => {
        onCurrentTime(event.detail.plyr.currentTime);
      });

      player.current.on('pause', () => {
        onSetPause(true);
      });

      player.current.on('play', () => {
        onSetPause(false);
      });

      setCurrentSource(src);
    }
  }, [controls, onCurrentTime, onSetPause, src, currentSource, videoElement, isLoading]);

  // compute target pixels for absolute position
  // K = video width / video height
  // if K >= 1:
  //   w` = window width
  //   h` = window height / K
  // else:
  //   w` = window width * K
  //   h` = window height
  const videoAspectRatio = videoWidth && videoHeight ? videoWidth / videoHeight : 1;
  let targetContainerWidth =
    videoAspectRatio > 1 ? containerWidthHere : containerHeightHere * videoAspectRatio;
  let targetContainerHeight =
    videoAspectRatio > 1 ? containerWidthHere / videoAspectRatio : containerHeightHere;
  // and fit it
  if (targetContainerWidth > containerWidthHere) {
    const percent = containerWidthHere / targetContainerWidth;
    targetContainerWidth = containerWidthHere;
    targetContainerHeight = targetContainerHeight * percent;
  }
  if (targetContainerHeight > containerHeightHere) {
    const percent = containerHeightHere / targetContainerHeight;
    targetContainerHeight = containerHeightHere;
    targetContainerWidth = targetContainerWidth * percent;
  }

  // to center
  const targetContainerLeft = (containerWidthHere - targetContainerWidth) / 2;
  const targetContainerTop = (containerHeightHere - targetContainerHeight) / 2;
  // to fix positions
  const itemFaceBoxes = dataObject?.Objects?.map((item: any) => item?.face_box_ns || item?.face_box);

  return (
    <div className={classes.root}>
      <div
        ref={containerRef}
        className={cn(classes.container, {
          [classes.containerFullscreen]: fullscreenActive,
        })}
      >
        <Portal node={document && document.getElementsByClassName('plyr__video-wrapper')[0]}>
          {/* stats */}
          <div
            style={{
              position: 'absolute',
              width: targetContainerWidth,
              height: targetContainerHeight,
              top: targetContainerTop + 'px',
              left: targetContainerLeft + 'px',
              pointerEvents: 'none',
              zIndex: 2,
              padding: '5px',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              alignContent: 'space-between',
            }}
          >
            {dataObject?.Objects?.map((item: any, index) => {
              const face_box = item?.face_box_ns || item?.face_box;
              return (
                <VideoItem
                  key={item?.uid}
                  emotions={item?.emotions}
                  positions={face_box}
                  isPositionsNotScaled={!!item?.face_box_ns}
                  colorRgb={item?.color_rgb}
                  hr={item?.hr || ''}
                  rr={item?.rr}
                  eyes={item?.gazes?.eac}
                  bp={item?.bp}
                  sincerity={item?.sincerity}
                  showParamsAll={showParamsAll}
                  drawInListIndex={index + 1} // set actual index to fix item position
                  drawInListFaceBoxes={itemFaceBoxes}
                  itemType={'statsonly'}
                />
              );
            })}
          </div>
          {/* face boxes */}
          <div
            style={{
              position: 'absolute',
              width: targetContainerWidth,
              height: targetContainerHeight,
              top: targetContainerTop + 'px',
              left: targetContainerLeft + 'px',
              pointerEvents: 'none',
              zIndex: 2,
            }}
          >
            {dataObject?.Objects?.map((item: any, index) => {
              const face_box = item?.face_box_ns || item?.face_box;
              return (
                <VideoItem
                  key={item?.uid}
                  emotions={item?.emotions}
                  positions={face_box}
                  isPositionsNotScaled={!!item?.face_box_ns}
                  colorRgb={item?.color_rgb}
                  hr={item?.hr || ''}
                  rr={item?.rr}
                  eyes={item?.gazes?.eac}
                  bp={item?.bp}
                  sincerity={item?.sincerity}
                  showParamsAll={showParamsAll}
                  drawInListIndex={index + 1} // set actual index to fix item position
                  drawInListFaceBoxes={null}
                  itemType={'boxonly'}
                />
              );
            })}
          </div>
          <div
            style={{
              color: 'rgb(200,200,200)',
              position: 'absolute',
              bottom: targetContainerHeight / 30,
              left: targetContainerWidth / 20,
            }}
          >
            {Object.keys(dataMeanSincerity)
              .slice(0, 3)
              .map((k, i) => {
                const t =
                  dataMeanSincerity[k] || dataMeanSincerity[k] === 0
                    ? `rated ${Number(dataMeanSincerity[k]).toFixed(1)} sincere`
                    : '';
                return (
                  <div key={i}>
                    object {i + 1} - {t}
                  </div>
                );
              })}
          </div>
        </Portal>
        <video className={classes.video} ref={videoElement} playsInline preload="all" autoPlay />
      </div>
      {isUserManager
        ? <div><label className="switch switch-around" >
            <input type="checkbox" onClick={() => setShowParamsAll(!showParamsAll)} checked={showParamsAll} readOnly />
            <span className="slider round"></span>
            <div className='switch title'>&nbsp;Show emotions</div>
          </label></div>
        : <></>
      }
    </div>
  );
};

VideoPlayer.defaultProps = {
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'captions',
    'settings',
    'airplay',
    'mute',
    'volume',
    'fullscreen',
  ],
};

export default VideoPlayer;
