import axios from 'axios';
import { refreshTokens } from './auth';

export const API_PREFIX = '/emts/v3';
export const MARKET_BASE_URL = 'https://gleenr.app/';
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const api = axios.create({
  baseURL: API_BASE_URL + API_PREFIX,
  ...getAuthHeaders(),
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    // ignore errors from '/upload/file' from upload progress percents
    if (
      (error.response?.status === 401 && !originalRequest._retry) ||
      (error.message === 'Network Error' &&
        originalRequest.url !== '/auth/refresh' &&
        originalRequest.url !== '/upload/file')
    ) {
      try {
        const { data } = await refreshTokens();

        if (data) {
          localStorage.setItem('emts3tr', data.rtoken);
          localStorage.setItem('emts3ts', data.stoken);
          api.defaults.headers = { ...getAuthHeader() };

          originalRequest.headers = { ...originalRequest.headers, Authorization: getAuthToken() };
          return api.request(originalRequest);
        } else {
          return (window.location.href = '/login');
        }
      } catch (error) {}
    }

    return Promise.reject(error);
  }
);

export const onGetYoutubeInfo = (id: string) => {
  return axios.get(
    `https://www.googleapis.com/youtube/v3/videos?id=${id}&part=contentDetails&key=AIzaSyDqtxojc5Hei8JjWY-NOALTqcyzcFs_bIc`
  );
};

export const initHeadersCommon = (autht: string) => {
  api.defaults.headers.common['Authorization'] = autht;
};

export function getAuthToken() {
  return localStorage.getItem('emts3ts') || '';
}

export function getAuthHeader() {
  return { Authorization: getAuthToken() };
}

export function getAuthHeaders() {
  return { headers: { Authorization: getAuthToken() } };
}

export function decodeToken(token: string) {
  if (!token) {
    return null;
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
