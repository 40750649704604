import { capitalize, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mobileMedia } from '../../theme';
import { AnalysisPlayerLoaderHints } from './';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 15,
  },
  wrapper: {
    background: '#F5F7FF',
    padding: 24,
    display: 'flex',
    height: '80vh',
    minHeight: 400,
    maxHeight: 587,
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    [mobileMedia]: {
      padding: '24px 16px',
    },
    marginBottom: 32,
  },
  closeBtn: {
    position: 'absolute',
    right: 24,
    top: 24,
    padding: '13px 59px',
    [mobileMedia]: {
      right: 16,
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loadingProgress: {
    '& h5': {
      marginRight: 8,
      fontSize: 22,
      fontWeight: 300,
      minWidth: 50,
    },

    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },
  loadingText: {
    fontSize: 18,
    color: '#04129A',
    fontWeight: 500,
  },
  dotsWrapper: {
    display: 'flex',
    '& span': {
      border: '2px solid #9D1091',
      width: 13,
      minWidth: 13,
      height: 13,
      marginRight: 7,
      borderRadius: '50%',
      '&:last-child': {
        marginRight: 0,
      },
    },
    height: 20,
    overflow: 'hidden',
    alignItems: 'center',
    margin: '0 auto',
  },
}));

export type AnalysisLoadingType = 'processing' | 'fileSending' | '';

type Props = {
  onCloseClick: () => void;
};

export const DOTS_COUNT = 4;
export const ANIMATION_TIME = 750;

export const AnalysisPlayerLoader: React.FC<Props> = ({ onCloseClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentDotAnimation, setCurrentDotAnimation] = useState(1);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentDotAnimation((prev) => {
        if (prev === DOTS_COUNT) {
          return 1;
        }

        return prev + 1;
      });
    }, ANIMATION_TIME);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const dots = useMemo(() => {
    const result = [];
    for (let i = 1; i <= DOTS_COUNT; i++) {
      result.push(
        <span
          style={{
            animation: i === currentDotAnimation ? '.9s increaseDot' : 'none',
          }}
          key={i}
        ></span>
      );
    }

    return result;
  }, [currentDotAnimation]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <button className={cn('primary-btn', classes.closeBtn)} onClick={onCloseClick}>
          {capitalize(t('close'))}
        </button>
        <div className={classes.textWrapper}>
          <div className={classes.textWrapper}>
            <div className={classes.loadingProgress}>
              <div className={classes.dotsWrapper}>{dots}</div>
            </div>
            <div className={classes.loadingText}>GLEENING NOW!</div>
          </div>
        </div>
      </div>
      <AnalysisPlayerLoaderHints />
    </div>
  );
};
