import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import { generalSlice } from './generalSlice';
import { videoSlice } from './videoSlice';
import { dataSlice } from './dataSlice';
import { userSlice } from './userSlice';
import { reportSlice } from './report';

const store = configureStore({
  reducer: {
    general: generalSlice.reducer,
    video: videoSlice.reducer,
    data: dataSlice.reducer,
    user: userSlice.reducer,
    report: reportSlice.reducer,
  },
  middleware: new MiddlewareArray(),
});

export default store;
