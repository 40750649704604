import { capitalize } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { decodeToken, getAuthToken } from '../../api';
import { userSelector } from '../../store/userSlice';
import Modal from '../Modal';

export type PopupType =
  | 'fileSended'
  | 'success'
  | 'error'
  | 'videoDurationError'
  | 'uploadsLimitError'
  | '';

type Props = {
  type: PopupType;
  cleanLoadingState: () => void;
};

export const Popup: React.FC<Props> = ({ type, cleanLoadingState }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const user = useSelector(userSelector);
  const allowedVideoDuration = user.planDurationMaxSec || 0;

  const onModalCloseAfterSuccess = useCallback(() => {
    history.push('/catalog');
  }, [history]);

  const onModalCloseAfterError = useCallback(() => {
    cleanLoadingState();
  }, [cleanLoadingState]);

  const onModalFileSended = useCallback(() => {
    history.push('/catalog');
  }, [history]);

  const onOkClick: () => void = useMemo(() => {
    if (type === 'fileSended') {
      return onModalFileSended;
    }

    if (type === 'error' || type === 'videoDurationError') {
      return onModalCloseAfterError;
    }

    if (type === 'uploadsLimitError') {
      const url = decodeToken(getAuthToken())?.sub + 'collections/gleenr-packages' || '/';
      return () => window.location.replace(url);
    }

    return onModalCloseAfterSuccess;
  }, [type, onModalCloseAfterError, onModalCloseAfterSuccess, onModalFileSended]);

  const onCancelClick = type === 'uploadsLimitError' ? cleanLoadingState : null;

  const modalText: string = useMemo(() => {
    if (type === 'fileSended') {
      return `${capitalize(t('video_submited'))}. ${capitalize(t('wait_for_processing'))}`;
    }

    if (type === 'error') {
      return `${capitalize(t('an_error_occurred_while_uploading_the_file'))}. ${capitalize(
        t('try_again')
      )}`;
    }

    if (type === 'videoDurationError') {
      return `${capitalize(t('video_duration_error'))}. ${capitalize(
        t('maximum_video_duration')
      )} ${allowedVideoDuration} ${t('sec')}`;
    }

    if (type === 'uploadsLimitError') {
      return capitalize(t('extend_package_question'));
    }

    return `${capitalize(t('video_processing_ended'))}`;
  }, [allowedVideoDuration, type, t]);

  const modalTitle: string = useMemo(() => {
    if (type === 'fileSended') {
      return capitalize(t('video'));
    }

    if (type === 'error' || type === 'videoDurationError') {
      return capitalize(t('loading_error'));
    }

    if (type === 'uploadsLimitError') {
      return capitalize(t('uploads_limit_error'));
    }

    return `${capitalize(t('ready'))}`;
  }, [type, t]);

  return (
    <Modal
      onOkClick={onOkClick}
      text={modalText}
      title={modalTitle}
      onCancelClick={onCancelClick}
    />
  );
};
