import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

type HiddenElementsSelectors = string[];

const toggleElems = ({
  type,
  hiddenElementsSelectors = [],
}: {
  type: 'hide' | 'show';
  hiddenElementsSelectors: HiddenElementsSelectors;
}) => {
  const hiddenElements: HTMLElement[] = [];

  hiddenElementsSelectors.forEach((el) => {
    hiddenElements.push(...Array.from(document.querySelectorAll<HTMLElement>(el)));
  });

  hiddenElements.forEach((it) => {
    if (!it?.style) return;
    it.style.display = type === 'show' ? 'block' : 'none';
  });
};

export const exportElementToPdf = ({
  wrapperSelector,
  hiddenElementsSelectors,
  fileName = 'report',
}: {
  wrapperSelector: string;
  hiddenElementsSelectors: HiddenElementsSelectors;
  fileName: string;
}) => {
  toggleElems({
    type: 'hide',
    hiddenElementsSelectors,
  });
  const data: HTMLElement = document.querySelector(wrapperSelector);

  if (!data) return;

  const originStyle = { pd: data.style.padding };
  data.style.padding = '150px 100px 35px 100px';
  data.style.width = '1330px';

  html2canvas(data, { useCORS: true, scale: 5 }).then((canvas) => {
    const imgData = canvas.toDataURL('image/jpeg');

    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4',
    });

    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    pdf.addImage(imgData, 'JPEG', 0, 0, width, height);

    // reset
    data.style.padding = originStyle.pd;
    toggleElems({
      type: 'show',
      hiddenElementsSelectors,
    });

    pdf.save(`${fileName}.pdf`);
  });
};
