import { makeStyles } from '@material-ui/core';
import React from 'react';
import { mobileMedia } from '../../theme';
import { Footer, Header } from '../layouts';
import cn from 'classnames';
import PageLoader from '../PageLoader';
import { MOBILE_NAVBAR_HEIGHT } from '../Navbar';

const useStyles = makeStyles(() => ({
  wrapper: {
    [mobileMedia]: {
      paddingBottom: MOBILE_NAVBAR_HEIGHT,
    },
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 1270,
    margin: '0 auto',
    padding: '0 15px',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  hiddenContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    opacity: 0,
    pointerEvents: 'none',
    overflow: 'hidden',
  },
}));

type Props = {
  rootClassName?: string;
  showProgress?: boolean;
  loadContentInBackground?: boolean;
  hiddenDesktopHeader?: boolean;
};

export const PageWrapper: React.FC<Props> = ({
  children,
  rootClassName,
  showProgress = false,
  loadContentInBackground = false,
  hiddenDesktopHeader = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={cn(classes.root, rootClassName)}>
        <Header hiddenOnDesktop={hiddenDesktopHeader} />
        <main className={classes.main}>
          {showProgress ? (
            <>
              <PageLoader />
              {loadContentInBackground && <div className={classes.hiddenContent}>{children}</div>}
            </>
          ) : (
            children
          )}
        </main>
      </div>
      {!showProgress && <Footer />}
    </div>
  );
};
