import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMaxOccuringItems } from '../../utils/getMaxOccuringItems';
import { DataBlock } from './DataBlock';

type Props = {
  data: [number, number][];
};

export const BreathBlock: React.FC<Props> = ({ data: initialData }) => {
  const { t } = useTranslation();

  const data = initialData
    .filter((d) => !String(d[1]).includes('%') && !String(d[1]).includes('N/A'))
    .map((d) => [+d[0], +d[1]]);

  const lineData = [['seconds', 'value'], ...data];

  const highestOccuringItems = getMaxOccuringItems(
    data.map((d) => d[1]),
    3
  );

  const pieData = [
    ['Breath', 'Percents'],
    ...highestOccuringItems.map((i) => [`${i[0]}/${t('min')}`, i[1]]),
  ];

  const sinceritySlices = {
    0: {
      color: '#BA58B2',
    },
    1: {
      color: '#7A4D76',
    },
    2: {
      color: '#4d1548',
    },
  };

  return (
    <DataBlock
      colorSlices={sinceritySlices}
      lineData={lineData}
      pieData={pieData}
      lineChartType="LineChart"
    />
  );
};
