import { capitalize, makeStyles } from '@material-ui/core';
import React from 'react';
import cn from 'classnames';
import { exportElementToPdf } from '../../utils/pdf/exportElementToPdf';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  btn: {
    marginBottom: 20,
    maxWidth: 'fit-content',
    alignSelf: 'flex-end',
  },
}));

type Props = {
  wrapperSelector: string;
  beforeExport?: () => void;
  afterExport?: () => void;
};

export const PrintToPdfBtn: React.FC<Props> = ({ wrapperSelector, afterExport, beforeExport }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onClick = async () => {
    try {
      await beforeExport();

      exportElementToPdf({
        fileName: 'report',
        hiddenElementsSelectors: [],
        wrapperSelector,
      });
    } catch (error) {
      console.log(error);
    } finally {
      await afterExport();
    }
  };

  return (
    <button className={cn('primary-btn', classes.btn)} onClick={onClick}>
      {capitalize(t('export'))}
    </button>
  );
};
