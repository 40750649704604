// modules
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// types
import { AlgorithmType, GeneralState } from './types';

const initialState: GeneralState = {
  isOpenedModal: false,
  typeModal: '',
  algorithm: 'sincerity',
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setPopupOpen(state: GeneralState, action: PayloadAction<string>) {
      state.isOpenedModal = true;
      state.typeModal = action.payload;
    },
    setPopupClose(state: GeneralState) {
      state.isOpenedModal = false;
      state.typeModal = '';
    },
    setAlgorithmType(state: GeneralState, { payload }: PayloadAction<AlgorithmType>) {
      state.algorithm = payload;
    },
  },
});

export const { setPopupOpen, setPopupClose, setAlgorithmType } = generalSlice.actions;
export default generalSlice.reducer;
