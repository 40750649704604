// modules
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Line, Chart } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import 'chartjs-adapter-luxon';
import StreamingPlugin from 'chartjs-plugin-streaming';

// types
import { StoreState } from '../../../store/types';

Chart.register(StreamingPlugin);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    padding: '7px 3px',
    position: 'relative',
    width: '100%',
    maxHeight: '340px',
    '& canvas': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '@media(max-width:1115px)': {
      maxHeight: 300,
    },
  },
}));

interface ChartItemProps {
  color?: string;
  id?: number;
  dataSets: any;
}

const LineChart: React.FC<ChartItemProps> = React.memo(({ color, dataSets, id }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { fps } = useSelector((state: StoreState) => ({
    currentTime: state.video.currentTime,
    fps: state.video.fps,
  }));

  function getLabels(dataSets: any) {
    return dataSets.map((e: any) => {
      const sec = e ? e.frame_id / fps : 0;
      const fh = Math.floor(sec / 60 / 60);
      const fm = Math.floor(sec / 60) % 60;
      const fs = Math.floor(sec) % 60;
      return fh > 0
        ? fh + ':' + ('00' + fm).slice(-2) + ':' + ('00' + fs).slice(-2)
        : fm + ':' + ('00' + fs).slice(-2);
    });
  }

  const data = {
    labels: getLabels(dataSets),
    datasets: [
      {
        label: t('pulse'),
        data: dataSets.map((e: any) => {
          const faceData = e && e.Objects && e.Objects.find((e: any) => e.uid === id);
          return faceData && faceData.hr;
          // do not use parseInt - skip value if it is not 'number'
        }),
        fill: false,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        color: '#fff',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  return (
    <div className={classes.root}>
      <Line data={data} options={options} />
    </div>
  );
});

export default LineChart;
