import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getMe, loginUser, loginUserTr } from '../api/auth';
import { useLocation } from 'react-router-dom';
import { setUserState } from '../store/userSlice';
import { useDispatch } from 'react-redux';
import { PageWrapper } from '../components/PageWrapper';
import { logoCircleWrapperSvg, logoSvg } from '../img';
import cn from 'classnames';
import { LoginDots } from '../components/login';
import { mobileMedia } from '../theme';
import { MARKET_BASE_URL } from '../api';

const useStyles = makeStyles(() => ({
  header: {
    position: 'relative',
    minHeight: 200,
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoWrapper: {
    position: 'absolute',
    zIndex: -1,
    [mobileMedia]: {
      maxWidth: '100%',
    },
  },
  logo: {
    position: 'relative',
    bottom: 20,
  },
  main: {
    maxWidth: 900,
    margin: '0 auto',
    marginBottom: 200,
    [mobileMedia]: {
      marginBottom: 30,
      maxWidth: 400,
    },
  },
  mainTop: {
    marginBottom: 50,
    maxWidth: 431,
    margin: '0 auto',
  },
  mainTitle: {
    fontWeight: 700,
    color: '#9d1091',
    fontSize: 50,
    marginBottom: 15,
    textAlign: 'center',
  },
  mainPostTitle: {
    fontSize: 18,
  },
  forms: {
    display: 'flex',
    justifyContent: 'space-between',
    [mobileMedia]: {
      flexDirection: 'column',
    },
  },
  signUp: {
    fontSize: 18,
    fontWeight: 700,
    color: '#90278c',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [mobileMedia]: {
      order: 3,
    },
  },
  signUpTitle: {
    marginBottom: 33,
  },
  signUpBtn: {},
  signIn: {
    flex: 1,
    '& input': {
      marginBottom: 14,
    },
    [mobileMedia]: {
      order: 1,
    },
  },
  signInTitle: {
    color: '#90278c',
    marginBottom: 21,
    fontSize: 18,
  },
  signInBtn: {
    margin: '0 auto',
    display: 'block',
  },
}));

export type LoginFields = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [pageIsLoading, setPageIsLoading] = useState(true);

  const { i18n } = useTranslation();

  const [formValues, setFormValues] = useState<LoginFields>({
    email: '',
    password: '',
  });

  const changeFormValue = ({ field, value }: { field: keyof LoginFields; value: string }) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const authUser = useCallback(async () => {
    try {
      const { data } = await getMe();

      if (data?.lang) {
        await i18n.changeLanguage(data.lang);
      }

      if (data) {
        dispatch(
          setUserState({
            firstName: data?.first_name || '',
            planName: data?.plan_name || '-',
            lastName: data?.last_name || '',
            uploadsRemaining: data?.plan_month_max || 0,
            planDurationMaxSec: data?.plan_duration_max || 0,
            is_manager: data?.is_manager,
          })
        );
      }
    } catch (error) {}
  }, [dispatch, i18n]);

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();
      await loginUser(formValues);

      await authUser();

      history.push('/catalog');
    } catch (error) {}
  };

  // login with token if it is in query
  useEffect(() => {
    const loginWithToken = async (tr: string) => {
      try {
        setPageIsLoading(true);

        await loginUserTr({ tr });
        await authUser();
        history.push('/catalog');
      } catch (error) {
        // remove token from query on error
        history.push('/login');
      } finally {
        setPageIsLoading(false);
      }
    };

    const q = new URLSearchParams(location.search);
    const tr = q.get('tr');
    if (tr) {
      loginWithToken(tr);
    } else {
      setPageIsLoading(false);
    }
  }, [authUser, history, location]);

  return (
    <PageWrapper showProgress={pageIsLoading} hiddenDesktopHeader>
      <header className={classes.header}>
        <img className={classes.logoWrapper} src={logoCircleWrapperSvg} alt="" />
        <img className={classes.logo} src={logoSvg} alt="" />
      </header>
      <main className={classes.main}>
        <div className={classes.mainTop}>
          <h3 className={classes.mainTitle}>Welcome!</h3>
          <span className={classes.mainPostTitle}>
            Gleenr shows you the emotions you can’t see behind the words you hear, so you can prep
            for what happens next.
          </span>
        </div>
        <div className={classes.forms}>
          <div className={classes.signUp}>
            <h4 className={classes.signUpTitle}>Don’t have an account yet?</h4>
            <a
              href={MARKET_BASE_URL + 'collections/gleenr-packages'}
              className={cn('primary-btn', classes.signUpBtn)}
            >
              Sign up for a plan
            </a>
          </div>
          <LoginDots />
          <div className={classes.signIn}>
            <h4 className={classes.signInTitle}>Sign in</h4>
            <form>
              <input
                onChange={(e) =>
                  changeFormValue({
                    field: 'email',
                    value: e.target.value,
                  })
                }
                value={formValues.email}
                className="primary-input"
                type="text"
                placeholder="Email"
              />
              <input
                onChange={(e) =>
                  changeFormValue({
                    field: 'password',
                    value: e.target.value,
                  })
                }
                value={formValues.password}
                className="primary-input"
                type="password"
                placeholder="Password"
              />
              <button
                onClick={onSubmit}
                className={cn('primary-btn', classes.signInBtn)}
                type="submit"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </main>
    </PageWrapper>
  );
};
