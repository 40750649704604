import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState, StoreState } from './types';

const initialState: UserState = {
  planName: '',
  firstName: '',
  lastName: '',
  uploadsRemaining: 0,
  planDurationMaxSec: 0,
  is_manager: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserState(_, action: PayloadAction<UserState>) {
      return { ...action.payload };
    },
  },
});

export const userSelector = createSelector(
  (state: StoreState) => state.user,
  (user) => user
);

export const { setUserState } = userSlice.actions;
