import { capitalize, makeStyles, useMediaQuery } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mobileMedia } from '../../theme';
import { API_BASE_URL, API_PREFIX, getAuthToken } from '../../api';

const defaultFaceSize = 69;

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [mobileMedia]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    marginRight: 42,
    fontSize: 18,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    [mobileMedia]: {
      marginBottom: 16,
      marginRight: 0,
    },
  },
  itemsWrapper: {
    display: 'flex',
    overflow: 'hidden',
    overflowX: 'auto',
    [mobileMedia]: {
      maxWidth: '100%',
    },
  },
  itemWrapper: {
    borderRadius: 10,
    cursor: 'pointer',
    border: '2px solid transparent',
    padding: 4,
  },
  itemSelected: {
    borderColor: '#9D1091',
    transition: '.2s',
    '& img': {
      filter: 'none',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 'inherit',
    filter: 'opacity(0.4)',
  },
}));

type Props = {
  faces: { image: string; id: number }[];
  faceSize?: number;
  selectedFaceId: number;
  setSelectedFaceId: (id: number) => void;
};

export const IdentifiedFaces: React.FC<Props> = ({
  faces,
  faceSize = defaultFaceSize,
  selectedFaceId,
  setSelectedFaceId,
}) => {
  const { t } = useTranslation();

  const isMobile = !useMediaQuery((theme: any) => theme.breakpoints.up('mobile'));

  const classes = useStyles();

  const { id } = useParams<{ id: string }>();

  const itemWrapperSize = isMobile ? defaultFaceSize : faceSize;

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>{capitalize(t('objects'))}</h4>
      <div className={classes.itemsWrapper} style={{ minHeight: itemWrapperSize }}>
        {faces.map((f, i) => (
          <div
            key={i}
            className={cn(classes.itemWrapper, {
              [classes.itemSelected]: selectedFaceId === f.id,
            })}
            onClick={() => setSelectedFaceId(f.id)}
            style={{
              width: itemWrapperSize,
              minWidth: itemWrapperSize,
              height: itemWrapperSize,
              minHeight: itemWrapperSize,
            }}
          >
            <img
              className={classes.image}
              src={`${API_BASE_URL}${API_PREFIX}/processed/video/${id}/f/${
                f.id
              }?t=${getAuthToken()}`}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                if (currentTarget.src !== f.image) currentTarget.src = f.image;
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
