import { capitalize } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataBlock } from './DataBlock';

type Props = {
  data: Array<any>;
};

export const EmotionsBlock: React.FC<Props> = ({ data: initialData }) => {
  const { t } = useTranslation();

  const data = initialData.filter((d) => Object.keys(d[1]).length !== 0);

  const lineDataNumbers: number[][] = [];
  data.forEach((d, i) => {
    lineDataNumbers[i] = [];
    let currNumbersArr = lineDataNumbers[i];

    const values = d[1];

    currNumbersArr.push(d[0]);
    currNumbersArr.push(values.Angry);
    currNumbersArr.push(values.Disgust);
    currNumbersArr.push(values.Excitement);
    currNumbersArr.push(values.Stress);
    currNumbersArr.push(values.Satisfaction);
    currNumbersArr.push(values.Neutral);
  });

  const totalEmotionValue = (emotion: string) => {
    let total = 0;

    data.forEach((d) => {
      total += d[1][emotion];
    });

    return total;
  };

  const emotionsData = [
    ['Emotions', 'Value'],
    [capitalize(t('anger')), totalEmotionValue('Angry')],
    [capitalize(t('disgust')), totalEmotionValue('Disgust')],
    [capitalize(t('excitement')), totalEmotionValue('Excitement')],
    [capitalize(t('stress')), totalEmotionValue('Stress')],
    [capitalize(t('happy')), totalEmotionValue('Satisfaction')],
    [capitalize(t('neutral')), totalEmotionValue('Neutral')],
  ];

  const lineData = [
    [
      'seconds',
      capitalize(t('anger')),
      capitalize(t('disgust')),
      capitalize(t('excitement')),
      capitalize(t('stress')),
      capitalize(t('happy')),
      capitalize(t('neutral')),
    ],
    [0, 0, 0, 0, 0, 0, 0],
    ...lineDataNumbers,
  ];

  const emotionsSlices = {
    0: {
      color: '#3366cc',
    },
    1: {
      color: '#dc3912',
    },
    2: {
      color: '#ff9900',
    },
    3: {
      color: '#109618',
    },
    4: {
      color: '#990099',
    },
    5: {
      color: '#0099c6',
    },
  };

  return <DataBlock colorSlices={emotionsSlices} lineData={lineData} pieData={emotionsData} />;
};
