// modules
import { useTranslation } from 'react-i18next';

const useBarSettings = () => {
  const { t } = useTranslation();

  const getEmotionTag = (name: string) => {
    if (name === 'Angry') {
      return t('anger');
    } else if (name === 'Disgust') {
      return t('disgust');
    } else if (name === 'Stress') {
      return t('stress');
    } else if (name === 'Discontent') {
      return t('dissatisfaction');
    } else if (name === 'Excitement') {
      return t('excitement');
    } else if (name === 'Neutral') {
      return t('neutral');
    } else if (name === 'Happy') {
      return t('happy');
    }
  };

  const getEmotionColor = (name: string) => {
    if (name === 'Angry') {
      return '#FF5144';
    } else if (name === 'Disgust') {
      return '#CC65CD';
    } else if (name === 'Stress') {
      return '#D7A050';
    } else if (name === 'Discontent') {
      return '#5F74FF';
    } else if (name === 'Excitement') {
      return '#F9DF70';
    } else if (name === 'Neutral') {
      return 'blue';
    } else if (name === 'Happy') {
      return '#9CF680';
    }
  };

  const getProgress = (hr: number, barValue: number) => {
    if (!hr) {
      return 0;
    } else if (hr > barValue) {
      return 100;
    } else {
      return 10 * Number(String(hr / barValue).replace('0.', ''));
    }
  };

  return { getEmotionTag, getEmotionColor, getProgress };
};

export default useBarSettings;
