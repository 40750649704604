import { capitalize, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px 0',
    borderTop: ' 1px solid #CCD6FC',
    borderBottom: ' 1px solid #CCD6FC',
    marginBottom: 44,
  },
  infoItems: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 12%',
    '@media(max-width:450px)': {
      justifyContent: 'space-between',
    },
  },
  infoItem: {
    position: 'relative',
    marginRight: 68,
    '&:last-child': {
      marginRight: 0,
    },
    display: 'flex',
    justifyContent: 'center',
    height: 60,
    minWidth: 50,
    '@media(max-width:450px)': {
      marginRight: 25,
    },
  },
  itemNumber: {
    color: '#9D1091',
    fontWeight: 700,
    fontSize: 28,
    position: 'absolute',
    borderRadius: '50%',
    border: '1px solid #CCD6FC',
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    alignSelf: 'flex-end',
    lineHeight: '23px',
    backgroundColor: '#fff',
    zIndex: 12,
  },
}));

type Props = {
  total: number;
  remaining: number;
  stored: number;
};

export const CatalogHeader: React.FC<Props> = ({ total, remaining, stored }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.infoItems}>
        <div className={classes.infoItem}>
          <div className={classes.itemNumber}>{total}</div>
          <span className={classes.itemText}>Gleens</span>
        </div>
        <div className={classes.infoItem}>
          <div className={classes.itemNumber}>{remaining}</div>{' '}
          <span className={classes.itemText}>{capitalize(t('remaining'))}</span>
        </div>
        <div className={classes.infoItem}>
          <div className={classes.itemNumber}>{stored}</div>{' '}
          <span className={classes.itemText}>{capitalize(t('stored'))}</span>
        </div>
      </div>
    </div>
  );
};
