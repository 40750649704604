import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 50,
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
    color: '#90278c',
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 7,
  },
  boldText: {
    fontWeight: 700,
  },
}));

type Props = {
  username: string;
  planName: string;
  uploadsLeft: number;
};

export const CatalogUserPlan: React.FC<Props> = ({ planName, uploadsLeft, username }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>Hey, {username}!</div>
      <div className={classes.paragraph}>
        You’re using <span className={classes.boldText}>{planName}</span>
      </div>
      <div className={classes.paragraph}>
        You have <span className={classes.boldText}>{uploadsLeft}</span> upload
        {uploadsLeft > 1 ? 's' : ''} left for this month.
      </div>
    </div>
  );
};
