import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import useBarSettings from '../../../hooks/useBarSettings';
import { LinearBarS } from '../../LinearBar';

const useStyles = makeStyles<Theme, themeProps>(() => ({
  root: {
    zIndex: 1000,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  linear: {
    marginRight: -5,
    backgroundColor: '#5A5C5D',
  },
  title: {
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#000',
    marginRight: 10,
  },
  bar: {
    flex: 1,
  },
  colorPrimary: {
    background: (props) => props.getEmotionColor(props.name),
  },
  barColorPrimary: {
    background: (props) => props.getEmotionColor(props.name),
  },
  barName: {
    width: '35%',
    '@media(max-width:1115px)': {
      width: 165,
    },
  },
}));

interface BarProps {
  name: string;
  value: number;
  icon: string;
}

interface themeProps {
  getEmotionColor(name: string): string | undefined;
  name: string;
}

const Bar: React.FC<BarProps> = ({ name, value, icon }) => {
  const { getEmotionTag, getEmotionColor } = useBarSettings();
  const classes = useStyles({ getEmotionColor, name });
  const colorPercent = 100 - Math.min(Math.max(0, value || 0), 100);
  const color = `rgb(${Math.floor(colorPercent)},${Math.floor(
    (150 * colorPercent) / 100.0
  )},${Math.floor(150 + colorPercent)})`;

  return (
    <div className={classes.root}>
      <div className={classes.barName}>
        <h5 className={classes.title}>{getEmotionTag(name)}</h5>
      </div>
      <div className={classes.bar}>
        <LinearBarS value={value || 0} height={11} backgroundImage={`url(${icon})`} color={color} />
      </div>
    </div>
  );
};

export default Bar;
