import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { capitalize, Dialog, DialogActions, DialogContent } from '@material-ui/core';

import { closeModalSvg } from '../../img';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 240,
  },
  bg: {
    backgroundColor: '#fff',
  },
  title: {
    color: '#000',
    marginBottom: 20,
    fontWeight: 700,
    fontSize: 18,
    display: 'flex',
    position: 'relative',
    paddingRight: 40,
    alignItems: 'center',
  },
  content: {
    fontSize: 16,
    color: '#000',
  },
  closeModal: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
  },
}));

type Props = {
  closeModal?: () => void;
  title: string;
  text: string;
  onOkClick?: () => void;
  onCancelClick?: () => void;
};

const Modal: React.FC<Props> = ({ closeModal, title, onOkClick, text, onCancelClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: '#fff',
          padding: 32,
          borderRadius: 16,
        },
      }}
      fullWidth={true}
      maxWidth="xs"
      open={true}
      className={classes.root}
    >
      <h4 className={classes.title}>
        <span>{title}</span>
        <img onClick={closeModal} className={classes.closeModal} src={closeModalSvg} alt="" />
      </h4>
      <DialogContent className={classes.content} style={{ margin: 0, padding: 0 }}>
        {text}
      </DialogContent>
      <DialogActions style={{ padding: 0, paddingTop: 56 }}>
        {onCancelClick && (
          <button className="secondary-btn" onClick={onCancelClick}>
            {capitalize(t('cancel'))}
          </button>
        )}
        <button className="primary-btn" onClick={onOkClick || closeModal}>
          {capitalize(t('ok'))}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
