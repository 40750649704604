import { capitalize, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React, { useRef } from 'react';
import { Chart, GoogleChartWrapperChartType } from 'react-google-charts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { pdfIsExportingSelector } from '../../store/report';
import { ColoredLabels } from './ColoredLabels';

const initialPieHeight = 150;
const exportingPieHeight = 100;
const initialLineHeight = 300;
const exportingLineHeight = 200;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '@media(max-width:1200px)': {
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    position: 'relative',
    marginTop: 50,
  },
  rootExporting: {
    minHeight: 'auto',
    marginTop: 20,
  },
  rootExportingAdditional: {
    minHeight: 320,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  pieWrapper: {
    flex: '0 1 25%',
    position: 'relative',
    '@media(max-width:1200px)': {
      flex: '100%',
      margin: '0 auto',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  pieExporting: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labels: {
    marginTop: 28,
    order: 1,
    fontSize: 16,
    fontWeight: 700,
  },
  labelsExporting: {
    marginTop: 0,
    marginRight: 15,
    fontSize: 16,
    fontWeight: 400,
  },
  line: {
    position: 'relative',
    '@media(max-width:1200px)': {
      flex: '100%',
      maxWidth: '100%',
      marginTop: '40px',
      marginLeft: 0,
      maxHeight: 300,
    },
    flex: 1,
    height: initialLineHeight,
    bottom: 30,
    marginLeft: 20,
  },
  lineExporting: {
    minHeight: 200,
    height: 200,
  },
  lineExportingAdditional: {
    width: '100%',
    marginLeft: 0,
    bottom: 0,
    '& .report-area-chart-wrapper': {
      minHeight: 200,
      top: 18,
      position: 'relative',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: 200,
  },
  horizontalScroll: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '@media(max-width:1200px)': {
      height: '100%',
      maxHeight: initialLineHeight,
      '& .report-area-chart-wrapper': {
        maxHeight: initialLineHeight,
        minHeight: initialLineHeight,
      },
    },
  },
  lineLabel: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'italic',
    display: 'block',
    textAlign: 'center',
  },
}));

const lineOptions = {
  title: '',
  curveType: 'function',
  legend: 'none',
};

const pieOptions = {
  title: '',
  pieHole: 0.4,
  is3D: false,
  pieSliceText: 'none',
  legend: {
    position: 'labeled',
    textStyle: { color: 'transparent', fontSize: 14 },
    alignment: 'center',
  },
  pieSliceTextStyle: {
    fontSize: 9,
  },
  chartArea: { width: '90%', height: '100%' },
};

type Props = {
  pieData: (string | number)[][];
  lineData?: any[];
  colorSlices: Record<
    number,
    {
      color: string;
    }
  >;
  lineChartType?: GoogleChartWrapperChartType;
  lineComponent?: React.ReactNode;
  withHorizontalScroll?: boolean;
  applyExportingStyles?: boolean;
};

export const DataBlock: React.FC<Props> = ({
  pieData,
  lineData: initialLineData = [],
  colorSlices,
  lineChartType = 'LineChart',
  lineComponent,
  withHorizontalScroll,
  applyExportingStyles = true,
}) => {
  const classes = useStyles();
  const pdfIsExporting = useSelector(pdfIsExportingSelector);

  const lineDataIsEmpty = initialLineData.length === 1;
  const lineWrapperRef = useRef<HTMLDivElement>(null);

  const lineData = lineDataIsEmpty ? [...initialLineData, [0, 0]] : initialLineData;

  const { t } = useTranslation();
  const xAxisLabel = capitalize(t('seconds'));

  const labels = pieData.slice(1, pieData.length).map((em, i) => ({
    text: String(em[0]),
    color: (colorSlices as any)[i].color as string,
  }));

  let lineHeight = pdfIsExporting ? exportingLineHeight : initialLineHeight;
  if (!applyExportingStyles) {
    lineHeight = 250;
  }
  const pieHeight = pdfIsExporting ? exportingPieHeight : initialPieHeight;

  return (
    <div
      className={cn(classes.root, {
        [classes.rootExporting]: pdfIsExporting,
        [classes.rootExportingAdditional]: applyExportingStyles && pdfIsExporting,
      })}
    >
      <div
        className={cn(classes.pieWrapper, {
          [classes.pieExporting]: applyExportingStyles && pdfIsExporting,
        })}
      >
        <Chart
          chartType="PieChart"
          width="300px"
          height={pieHeight}
          data={pieData}
          options={{ ...pieOptions, slices: colorSlices }}
          style={{
            order: applyExportingStyles && pdfIsExporting ? 2 : 1,
          }}
        />
        <div
          className={cn(classes.labels, {
            [classes.labelsExporting]: applyExportingStyles && pdfIsExporting,
          })}
        >
          <ColoredLabels labels={labels} />
        </div>
      </div>
      <div
        className={cn(classes.line, {
          [classes.horizontalScroll]: withHorizontalScroll,
          [classes.lineExporting]: pdfIsExporting,
          [classes.lineExportingAdditional]: applyExportingStyles && pdfIsExporting,
        })}
        ref={lineWrapperRef}
      >
        {lineComponent ? (
          lineComponent
        ) : (
          <Chart
            chartType={lineChartType}
            height={lineHeight}
            data={lineData}
            options={{
              ...lineOptions,
              vAxis: {
                minValue: 0,
                viewWindow: {
                  min: 0,
                },
              },
              hAxis: {
                title: xAxisLabel,
              },
              chartArea: {
                left: 40,
                width: '100%',
                height: '70%',
              },
            }}
          />
        )}
        {lineComponent && <span className={classes.lineLabel}>{xAxisLabel}</span>}
      </div>
    </div>
  );
};
