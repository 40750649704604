import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: '0 1 25%',
    '@media(max-width:1200px)': {
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    maxWidth: 250,
  },
  label: {
    '& h5': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    '& span': {
      borderRadius: 4,
      marginRight: 12,
      width: 16,
      height: 16,
    },
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 55%',
    marginBottom: 15,
    '&:nth-child(2n)': {
      marginRight: 0,
      flex: '0 0 45%',
      '@media(max-width:1200px)': {
        flex: 0,
      },
    },
    '@media(max-width:1200px)': {
      flex: 0,
    },
  },
}));

export type ColoredLabelType = {
  text: string;
  color: string;
};

type Props = {
  labels: ColoredLabelType[];
};

export const ColoredLabels: React.FC<Props> = ({ labels }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {labels.map((t, i) => (
        <div key={i} className={classes.label}>
          <span style={{ background: t.color }}></span>
          <h5>{t.text}</h5>
        </div>
      ))}
    </div>
  );
};
