import React from 'react';

interface FaceBoxProps {
  color: string;
}

const FaceBox: React.FC<FaceBoxProps> = ({ color }) => {
  return (
    <>
      <svg viewBox="0 0 100 100">
        <path d="M25,2 L2,2 L2,25" fill="none" stroke={color} strokeWidth="2" />
        <path d="M2,75 L2,98 L25,98" fill="none" stroke={color} strokeWidth="2" />
        <path d="M75,98 L98,98 L98,75" fill="none" stroke={color} strokeWidth="2" />
        <path d="M98,25 L98,2 L75,2" fill="none" stroke={color} strokeWidth="2" />
      </svg>
    </>
  );
};

export default FaceBox;
