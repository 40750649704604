import { makeStyles } from '@material-ui/core';
import React from 'react';
import { mobileMedia } from '../../theme';
import { CatalogItem } from '../../types/catalog';
import { CatalogListItem } from './VideoListItem';

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    marginBottom: 31,
    fontWeight: 700,
    fontSize: 18,
    [mobileMedia]: {
      fontSize: 16,
    },
    '@media(max-width:620px)': {
      marginBottom: 15,
    },
  },
  itemsList: {
    listStyle: 'none',
    '@media(max-width:620px)': {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      width: '100%',
    },
    maxHeight: 885,
    overflowY: 'auto',
    paddingRight: 5,
  },
}));

type Props = {
  items: CatalogItem[];
  removeItemById: (fileId: string) => void;
  isUserManager?: boolean;
};

export const CatalogVideoList: React.FC<Props> = ({ items, removeItemById, isUserManager }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Your recent gleens…</h4>
      <ul className={classes.itemsList}>
        {items.map((item, ind) => (
          <CatalogListItem key={ind} {...item} removeItemById={removeItemById} isUserManager={isUserManager} />
        ))}
      </ul>
    </div>
  );
};
