import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ANIMATION_TIME, DOTS_COUNT } from './Loader';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 536,
    margin: '0 auto',
    marginBottom: 180,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
  },
}));

const hints = [
  "Impressions of other people's faces (e.g., trustworthiness)"
   + " have long been thought to be evoked by morphological variation (e.g., upturned mouth) in a universal, fixed manner."
   + " However, recent research suggests that these impressions vary considerably across perceivers and targets' social-group memberships....the findings suggest"
   + " that the structure of people's impressions of others' faces is driven"
   + " not only by the morphological variation of the face but also by learned stereotypes about social groups."
   + ' (Source: "Facial Impressions Are Predicted by the Structure of Group Stereotypes", by Xie, Flake, Stolier, et al.)'
   + " This is why we believe Gleenr can adjust for these biases"
];

export const AnalysisPlayerLoaderHints = () => {
  const classes = useStyles();

  const [currentHintIndex, setCurrentHintIndex] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentHintIndex((prev) => {
        if (prev + 1 === hints.length) {
          return 0;
        }

        return prev + 1;
      });
    }, ANIMATION_TIME * DOTS_COUNT);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Did you know?</h3>
      <div className={classes.text}>{hints[currentHintIndex]}</div>
    </div>
  );
};
