import { makeStyles } from '@material-ui/core';
import React from 'react';
import { mobileMedia } from '../../theme';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginRight: 120,
    marginLeft: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [mobileMedia]: {
      order: 2,
      margin: '46px 0px',
      flexDirection: 'row',
    },
  },
  dot: {
    width: 3,
    backgroundColor: '#91268C',
    height: 3,
    borderRadius: '50%',
  },
}));

const DOTS_COUNT = 11;

export const Dots = () => {
  const classes = useStyles();
  const dotsArr = new Array(DOTS_COUNT).fill(true);

  return (
    <div className={classes.wrapper}>
      {dotsArr.map((_, i) => (
        <div key={i} className={classes.dot}></div>
      ))}
    </div>
  );
};
