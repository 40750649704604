import { capitalize, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { API_BASE_URL, API_PREFIX, getAuthToken } from '../../api';
import { deleteCatalogItem } from '../../api/catalog';
import { documentCircleSvg, downloadVideoSvg, playCircleSvg, removeIconSvg } from '../../img';
import { mobileMedia } from '../../theme';
import { CatalogItem } from '../../types/catalog';
import Modal from '../Modal';
import { VideoItemButton } from './VideoItemButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 31,
    position: 'relative',
    '@media(max-width:620px)': {
      width: 180,
      minWidth: 180,
      marginRight: 35,
      marginBottom: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  preview: {
    '& img': {
      height: 'auto',
      maxHeight: 166,
      minHeight: 140,
      [mobileMedia]: {
        height: 90,
      },
    },
    backgroundColor: '#F5F7FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  date: {
    fontSize: 14,
    marginBottom: 10,
  },
  buttons: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: 10,
    bottom: 0,
  },
}));

type Props = CatalogItem & {
  removeItemById: (fileId: string) => void;
  isUserManager?: boolean;
};

export const CatalogListItem = React.memo<Props>(
  ({ date, fileId, preview, withPlayer, withReport, withExport, withExportBio, removeItemById, isUserManager }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [modalType, setModalType] = useState<'removeItem' | ''>('');

    const onDownloadClick = async () => {
      try {
        window.location.href = `${API_BASE_URL}${API_PREFIX}/processed/video/${fileId}/export?t=${getAuthToken()}&type=alt`;
      } catch (e) {
        console.log(e);
      }
    };

    const onDownloadBioClick = async () => {
      window.location.href = `${API_BASE_URL}${API_PREFIX}/processed/video/${fileId}/export?t=${getAuthToken()}&type=def`;
    };

    const onOpenInPlayerClick = () => {
      history.push(`/analysis/${fileId}`);
    };

    const onReportClick = () => {
      history.push(`/report/${fileId}`);
    };

    const onRemoveItemClick = () => {
      setModalType('removeItem');
    };

    const onRemoveItemAccept = async () => {
      try {
        await deleteCatalogItem(fileId);

        removeItemById(fileId);
        setModalType('');
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <>
        {modalType && (
          <Modal
            closeModal={() => setModalType('')}
            text={capitalize(t('delete_video_confirm_text'))}
            title={capitalize(t('delete_video'))}
            onOkClick={onRemoveItemAccept}
            onCancelClick={() => setModalType('')}
          />
        )}
        <li className={classes.root}>
          <div className={classes.date}>{date}</div>
          <div className={classes.preview}>
            <img src={preview} alt="" />
          </div>
          <div className={classes.buttons}>
            <VideoItemButton
              disabled={!withPlayer}
              onClick={onOpenInPlayerClick}
              image={playCircleSvg}
            />
            <VideoItemButton
              imgStyles={{
                border: '2px solid #fff',
              }}
              disabled={!withReport}
              onClick={onReportClick}
              image={documentCircleSvg}
            />
            <VideoItemButton
              disabled={!withExport}
              onClick={onDownloadClick}
              image={downloadVideoSvg}
            />
            {isUserManager && <VideoItemButton
              disabled={!withExportBio}
              onClick={onDownloadBioClick}
              image={downloadVideoSvg}
              imgStyles={{
                background: 'rgb(255,130,0)',
              }}
            />}
            <VideoItemButton
              disabled={!fileId}
              onClick={onRemoveItemClick}
              image={removeIconSvg}
              imgStyles={{
                border: '2px solid #fff',
                padding: 3,
              }}
            />
          </div>
        </li>
      </>
    );
  }
);
