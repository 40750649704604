import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useState } from 'react';
import { useIsMounted } from '../../hooks/useIsMounted';

const BUTTON_SIZE = 40;

export type StyleProps = {
  isLoading: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    display: 'flex',
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
    cursor: 'pointer',
    borderRadius: '50%',
    flex: `0 1 ${BUTTON_SIZE}px`,

    '&:disabled': {
      backgroundColor: ({ isLoading }) => (isLoading ? 'none' : 'grey'),
      cursor: 'default',
      '& img': {
        backgroundColor: ({ isLoading }) => (isLoading ? 'none' : 'grey'),
      },
    },
  },
  image: {
    width: '100%',
    borderRadius: '50%',
    backgroundColor: '#9D1091',
  },
}));

type Props = {
  onClick: () => void | Promise<any>;
  image: string;
  disabled: boolean;
  imgStyles?: CSSProperties;
};

export const VideoItemButton: React.FC<Props> = ({ onClick, image, disabled, imgStyles }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const styleProps: StyleProps = {
    isLoading: isDownloading,
  };
  const classes = useStyles(styleProps);

  const isMounted = useIsMounted();

  const onButtonClick = async () => {
    try {
      setIsDownloading(true);
      await onClick();
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted()) {
        setIsDownloading(false);
      }
    }
  };

  if (disabled)
    imgStyles = {...imgStyles, background: 'gray'}

  return (
    <button onClick={onButtonClick} disabled={disabled} className={classes.root}>
      {isDownloading ? (
        <div style={{ width: '100%' }}>
          <CircularProgress color={'secondary'} style={{ width: '100%', height: '100%' }} />
        </div>
      ) : (
        <img style={imgStyles} className={classes.image} src={image} alt="" />
      )}
    </button>
  );
};
