import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { downloadFileSvg } from '../../img';
import { API_BASE_URL, API_PREFIX, getAuthToken } from '../../api';

const useStyles = makeStyles(() => ({
  root: {
    background: 'transparent',
    cursor: 'pointer',
  },
  img: {
    filter: "invert(100%) brightness(0.9) hue-rotate(270deg)", // convert pink to orange
  }
}));

export const DownloadVideoBtn: React.FC<{isEnabled: boolean, title: string|null, params?: string|null, changeColor?: boolean}> = ({
  isEnabled, title, params, changeColor
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const onDownloadClick = async () => {
    const q = params ? `&type=${params}` : ''; // additional query params
    window.location.href=`${API_BASE_URL}${API_PREFIX}/processed/video/${id}/export?t=${getAuthToken()}${q}`;
  };

  return (
    <button onClick={onDownloadClick} className={classes.root} disabled={!isEnabled}>
      <img className={changeColor && classes.img} src={downloadFileSvg} alt="" />
      <div>{title}</div>
    </button>
  );
};
