import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
}));

const PageLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderWrapper}>
      <CircularProgress color="secondary" size={64} />
    </div>
  );
};

export default PageLoader;
