import { api, getAuthHeader } from '.';
import { LoginFields } from '../pages/login';
import { getCatalogList } from './catalog';

export const loginUser = async ({ email, password }: LoginFields) => {
  const resp = await api.post('/auth/login', {
    email,
    password,
  });

  const { data } = resp;

  localStorage.setItem('emts3tr', data.rtoken);
  localStorage.setItem('emts3ts', data.stoken);

  api.defaults.headers = getAuthHeader();

  return resp;
};

export const loginUserTr = async ({ tr }: any) => {
  const resp = await api.post('/auth/login/tr', { tr });

  const { data } = resp;

  localStorage.setItem('emts3tr', data.rtoken);
  localStorage.setItem('emts3ts', data.stoken);

  api.defaults.headers = getAuthHeader();

  return resp;
};

export const getMe = async (): Promise<{ data: any }> => {
  const { data: userData } = await api.get('/auth/me');
  const { data: uploadsData } = await getCatalogList({
    beg: 0,
    end: 0,
  });

  return { data: { ...userData, ...uploadsData } };
};

export const getMeOnly = async (): Promise<{ data: any }> => {
  return api.get('/auth/me');
};

export const getMeUpdate = async () => {
  return api.get('/auth/me/update');
};

export const getMeUpdated = async () => {
  return api.get('/auth/me/updated');
};

export const logoutUser = async () => {
  const resp = await api.get('/auth/logout');

  localStorage.removeItem('emts3tr');
  localStorage.removeItem('emts3ts');

  return resp;
};

export const refreshTokens = async () => {
  const resp = await api.get('/auth/refresh', {
    headers: {
      Authorization: localStorage.getItem('emts3tr') || '',
    },
  });

  return resp;
};
