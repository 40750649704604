import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportState, StoreState } from './types';

const initialState: ReportState = {
  pdfIsExporting: false,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setPdfIsExporting(state, action: PayloadAction<boolean>) {
      state.pdfIsExporting = action.payload;
    },
  },
});

export const pdfIsExportingSelector = createSelector(
  (state: StoreState) => state.report,
  (report) => report.pdfIsExporting
);

export const { setPdfIsExporting } = reportSlice.actions;
