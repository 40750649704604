import { capitalize, makeStyles } from '@material-ui/core';
import axios from 'axios';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { decodeToken, getAuthToken } from '../api';
import { getFileProcessing } from '../api/analysis';
import { uploadFile, uploadLink } from '../api/upload';
import { AnalysisPopup, AnalysisPopupType } from '../components/analysis';
import { AnalysisPlayerLoader } from '../components/analysis-player';
import { AnalysisLoadingType } from '../components/analysis-player/Loader';
import { PageWrapper } from '../components/PageWrapper';
import { uploadLinkSvg, uploadVideoSvg } from '../img';
import { facebookIconSvg, instagramIconSvg, youtubeIconSvg } from '../img/socials';
import { userSelector } from '../store/userSlice';
import { mobileMedia } from '../theme';
import { getVideoDuration } from '../utils/video/getVideoDuration';

const useStyles = makeStyles(() => ({
  pageRoot: {
    minHeight: '100vh',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    height: '100%',
    maxHeight: '580px',
    minHeight: 460,
    [mobileMedia]: {
      flexWrap: 'wrap',
      padding: '0px 16px',
      maxHeight: 'none',
    },
  },
  uploadBlock: {
    flex: 1,
    marginRight: 56,
    '&:last-child': {
      marginRight: 0,
    },
    borderRadius: 8,
    border: '1px dashed #9D1091',
    padding: '20px 50px 90px 50px',
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width:1000px)': {
      padding: '20px 20px 90px 20px',
    },
    [mobileMedia]: {
      flex: '100%',
      marginRight: 0,
      border: 'none',
      paddingBottom: 36,
      maxWidth: '100%',
    },
  },
  uploadVideo: {
    [mobileMedia]: {
      borderBottom: '1px solid #CCD6FC',
      borderRadius: 0,
    },
  },
  uploadLink: {
    border: '1px solid #CCD6FC',
    paddingBottom: 33,
    [mobileMedia]: {
      border: 'none',
    },
  },
  blockHeader: {
    position: 'relative',
    '& span': {
      color: '#90278C',
      fontWeight: 700,
      fontSize: 22,
      textAlign: 'center',
      display: 'block',
      position: 'relative',
      bottom: '25%',
    },
    flex: '1',
    margin: '0 auto',
    marginBottom: 41,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    [mobileMedia]: {
      flex: '0 1 150px',
      '& span': {
        bottom: '16%',
      },
    },
  },
  linkBlockHeader: {
    [mobileMedia]: {
      flex: '0 1 250px',
    },
    marginTop: '16px',
    marginBottom: '2px',
    '& span': {
      bottom: '22%',
    },
  },
  imageWrapper: {
    background: 'radial-gradient(50% 50% at 50% 50%, #CCD6FC 0%, rgba(245, 247, 255, 0) 100%)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 86,
    },
  },
  uploadBtn: {
    margin: '0 auto',
    width: 300,
    [mobileMedia]: {
      width: 350,
      maxWidth: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadLinkBtn: {
    width: '100%',
    marginBottom: 29,
    [mobileMedia]: {
      width: 350,
      maxWidth: '100%',
    },
  },
  socials: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      marginRight: 24,
      width: 32,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  linkInput: {
    marginBottom: 24,
    border: '1px solid #5479F6',
    borderRadius: 5,
    fontSize: 16,
    padding: '10px',
    width: '100%',
    [mobileMedia]: {
      width: 350,
      maxWidth: '100%',
      margin: '0 auto',
      marginBottom: 24,
    },
  },
  selectVideoInput: {
    width: '100%',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    cursor: 'pointer',
    opacity: 0,
  },
}));

const links = [youtubeIconSvg, facebookIconSvg, instagramIconSvg];

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const AnalysisPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const user = useSelector(userSelector);
  // check duration here if it is loaded from gleenr or check with backend
  const tokenIss = decodeToken(getAuthToken())?.iss || null;
  const allowedVideoDuration = user.planDurationMaxSec || 0;

  const [isLoading, setIsLoading] = useState(false);
  const [loadingType, setLoadingType] = useState<AnalysisLoadingType>('');

  const [modalType, setModalType] = useState<AnalysisPopupType>('');
  const modalIsOpened = modalType !== '';

  const [totalLoaded, setTotalLoaded] = useState(0);

  const [fileId, setFileId] = useState('');

  const [link, setLink] = useState('');

  const cleanLoadingState = useCallback(() => {
    setIsLoading(false);
    setModalType('');
    setLoadingType('');
    setFileId('');
  }, []);

  useEffect(() => {
    let timerId: number | null = null;

    if (loadingType === 'processing' && fileId) {
      timerId = window.setInterval(async () => {
        try {
          const data = await getFileProcessing({
            fileId,
            prevPercents: totalLoaded,
          });

          // show text with button if in query or show process percents
          if (data.status === 'query') {
            setModalType('fileSended');
          } else if (data.status === 'process') {
            setModalType('');
          }

          if (data.status === 'done') {
            setModalType('success');
            clearInterval(timerId);
          }

          if (data.percents > 0) {
            setTotalLoaded(data.percents);
          }
        } catch (error) {
          console.log(error);
        }
      }, 2000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [fileId, loadingType, totalLoaded]);

  const onProgressChange = (progress: number) => {
    setTotalLoaded(progress);
  };

  const onCancelClick = () => {
    if (loadingType === 'processing' && isLoading) {
      return history.push('/catalog');
    }

    source.cancel();
    cleanLoadingState();
  };

  const afterUpload = async (status: string, newFileId: string) => {
    try {
      if (status === 'busy') {
        setModalType('error');
        return;
      }

      if (!newFileId) {
        setModalType('fileSended');
        return;
      }

      await getFileProcessing({
        fileId: newFileId,
        prevPercents: totalLoaded,
      });

      setFileId(newFileId);
      setLoadingType('processing');
      setTotalLoaded(0);
    } catch (error) {
      afterErrorUpload(error);
    }
  };

  const afterErrorUpload = (err: any) => {
    const requestIsCancelled = axios.isCancel(err);
    if (!requestIsCancelled) {
      console.log(err);
      setModalType('error');
    }
  };

  const onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      setLoadingType('fileSending');

      const file = e.target.files[0];
      if (!file) return;

      const videoDuration = await getVideoDuration({ file });

      if (videoDuration > allowedVideoDuration && tokenIss === 'gleenr.app') {
        return setModalType('videoDurationError');
      }

      const { data } = await uploadFile({
        file,
        onProgressChange,
        config: { cancelToken: source.token },
      });

      await afterUpload(data?.status || '', data?.fileid || '');
    } catch (err) {
      afterErrorUpload(err);
    }
  };

  const onLinkUpload = async () => {
    try {
      if (!link?.trim()) return;
      setIsLoading(true);

      const { data } = await uploadLink(link);

      await afterUpload(data?.status || '', data?.fileid || '');
    } catch (error) {
      afterErrorUpload(error);
    }
  };

  return (
    <PageWrapper rootClassName={classes.pageRoot}>
      {modalIsOpened && <AnalysisPopup cleanLoadingState={cleanLoadingState} type={modalType} />}
      {isLoading ? (
        <AnalysisPlayerLoader onCloseClick={onCancelClick} />
      ) : (
        <div className={classes.root}>
          <div className={cn(classes.uploadVideo, classes.uploadBlock)}>
            <div className={classes.blockHeader}>
              <div className={classes.imageWrapper}>
                <img src={uploadVideoSvg} alt="" />
              </div>
              <span>{capitalize(t('upload_new_video'))}</span>
            </div>
            <button
              className={cn('primary-btn', classes.uploadBtn)}
              style={{ marginTop: 'auto', position: 'relative' }}
            >
              <input
                type="file"
                onChange={onFileUpload}
                className={classes.selectVideoInput}
                accept="video/*"
                disabled={isLoading}
                onClick={(e) => {
                  if (user.uploadsRemaining === 0) {
                    setModalType('uploadsLimitError');
                    e.preventDefault();
                  }
                }}
              />
              {capitalize(t('select_video'))}
            </button>
          </div>
          <div className={cn(classes.uploadLink, classes.uploadBlock)}>
            <div className={cn(classes.blockHeader, classes.linkBlockHeader)}>
              <div className={classes.imageWrapper}>
                <img src={uploadLinkSvg} alt="" />
              </div>
              <span>{capitalize(t('use_link'))}</span>
            </div>
            <input
              type="text"
              className={classes.linkInput}
              placeholder={capitalize(t('insert_video_link'))}
              onChange={(e) => setLink(e.target.value)}
            />
            <button
              onClick={() => {
                if (user.uploadsRemaining === 0) {
                  setModalType('uploadsLimitError');
                } else {
                  onLinkUpload();
                }
              }}
              className={cn('primary-btn', classes.uploadBtn, classes.uploadLinkBtn)}
            >
              {capitalize(t('upload_video'))}
            </button>
            <div className={classes.socials}>
              {links.map((l, i) => (
                <img key={i} src={l} alt="" />
              ))}
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};
