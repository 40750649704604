import { api } from '.';

type FileProcessingInfo = {
  percents: number;
  status: 'process' | 'done' | 'query';
};

export const getFileProcessing = async ({
  fileId,
  prevPercents,
}: {
  fileId: string;
  prevPercents: number;
}): Promise<FileProcessingInfo> => {
  const { data } = await api.get('/process/progress');

  const file = data?.progress?.find((f: any) => f.fileid === fileId);

  if (!file) {
    // if done and no file data
    if (prevPercents > 0) {
      return {
        percents: 100,
        status: 'done',
      };
    }

    return {
      percents: 0,
      status: 'process',
    };
  }

  return {
    percents: Math.floor(file.percents),
    status: file.status,
  };
};
