import React, { useMemo } from 'react';
import { DataBlock } from './DataBlock';

export type SincerityBlockData = [number, number][];

type Props = {
  data: SincerityBlockData;
};

export const SincerityBlock: React.FC<Props> = ({ data }) => {
  const sincerityFrequency = useMemo(() => {
    const frequency = {
      sincerity: 0,
      insincerity: 0,
    };

    data?.forEach((val) => {
      const sincerityValue = val[1];
      if (sincerityValue > 50) {
        frequency.sincerity += 1;
      } else {
        frequency.insincerity += 1;
      }
    });

    return frequency;
  }, [data]);

  const pieData = [
    ['Sincerity type', 'Frequency'],
    ['Sincerity', sincerityFrequency.sincerity],
    ['Insincerity', sincerityFrequency.insincerity],
  ];

  const lineData = [['seconds', 'percents'], ...data];

  const sinceritySlices = {
    0: {
      color: '#BA58B2',
    },
    1: {
      color: '#7A4D76',
    },
    2: {
      color: '#4d1548',
    },
  };

  return (
    <DataBlock
      colorSlices={sinceritySlices}
      lineData={lineData}
      pieData={pieData}
      lineChartType="LineChart"
      applyExportingStyles={false}
    />
  );
};
