import { capitalize, makeStyles } from '@material-ui/core';
import React from 'react';
import { logoSvg } from '../../img';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {},
  logo: {
    marginBottom: 15,
    width: 230,
  },
  hint: {
    marginBottom: 25,
    fontSize: 28,
  },
  title: {
    fontSize: 45,
    fontWeight: 700,
    color: 'purple',
  },
}));

type Props = {
  wrapperClassName: string;
};

export const PdfReport: React.FC<Props> = ({ children, wrapperClassName }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={cn(classes.root, wrapperClassName)}>
      <img src={logoSvg} alt="" className={classes.logo} />
      <p className={classes.hint}>
        We’ve gleaned your video with all the results below, use this to inform to your next steps.
      </p>
      <h4 className={classes.title}>{capitalize(t('your_results'))}</h4>
      {children}
    </div>
  );
};
