import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 35,
    marginBottom: 13,
    color: '#9D1091',
    fontWeight: 700,
  },
}));

type Props = {
  text: string;
};

export const Title: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return <h2 className={classes.root}>{text}</h2>;
};
