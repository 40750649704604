import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    angry: Palette['primary'];
  }
  interface PaletteOptions {
    angry: PaletteOptions['primary'];
  }
  interface Palette {
    disgust: Palette['primary'];
  }
  interface PaletteOptions {
    disgust: PaletteOptions['primary'];
  }
  interface Palette {
    stress: Palette['primary'];
  }
  interface PaletteOptions {
    stress: PaletteOptions['primary'];
  }
  interface Palette {
    dissatisfaction: Palette['primary'];
  }
  interface PaletteOptions {
    dissatisfaction: PaletteOptions['primary'];
  }
  interface Palette {
    happy: Palette['primary'];
  }
  interface PaletteOptions {
    happy: PaletteOptions['primary'];
  }
  interface Palette {
    excitement: Palette['primary'];
  }
  interface PaletteOptions {
    excitement: PaletteOptions['primary'];
  }
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

export const mobileMedia = '@media(max-width:820px)';

const theme = createTheme({
  typography: {
    fontFamily: ['Jura', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#9d1091',
    },
    angry: {
      main: '#FF5144',
    },
    disgust: {
      main: '#CC65CD',
    },
    stress: {
      main: '#D7A050',
    },
    happy: {
      main: '#9CF680',
    },
    dissatisfaction: {
      main: '#5F74FF',
    },
    excitement: {
      main: '#F9DF70',
    },
    neutral: {
      main: 'blue',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      mobile: 820,
      sm: 850,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
