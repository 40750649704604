import { Data, ObjectData } from '../components/PulseChart/types';

// framesCountToSearch - use -1 to search in all values or use fps*30seconds
export const faceWithPreviousFrameEmotionsOnly = (
  statistics: Data[],
  actualFrameId: number,
  actualFace: ObjectData,
  framesCountToSearch: number,
) => {
  // rename
  const data = statistics;
  // get data from previous frame
  const maxFramesCountToSearch = Math.max(actualFrameId - framesCountToSearch, 0);
  for (let pfd = data.length - 1; pfd >= maxFramesCountToSearch; --pfd) {
    const previousFrameData = data[pfd];
    // skip not actual frames
    if (previousFrameData.frame_id >= actualFrameId) continue;
    // stop search when some frames are not in array
    if (previousFrameData.frame_id < maxFramesCountToSearch) break;
    const previousFaces = previousFrameData.Objects;
    // get face data with current face id
    const previousFace =
      previousFaces == null ? null : previousFaces.find((f) => f.uid === actualFace?.uid);

    if (previousFace != null && previousFace.emotions != null && previousFace.emotions.length > 0) {
      // found for this face - return emotions
      return previousFace.emotions;
    }
  }
  return actualFace?.emotions;
};

export const faceWithPreviousFrameEmotionsOnlyPts = (
  statistics: Data[],
  actualFrameId: number,
  actualFace: ObjectData,
  minTimeSecondsToSearch: number,
  ScaleSecPts: number,
) => {
  const minTimeSecondsToSearchPts = minTimeSecondsToSearch * ScaleSecPts;

  // statistics.reverse().find((e) => e && (e.pts <= timeSecPts) && (timeSecPts - e.pts) < ActualSecPts);

  // rename
  const data = statistics;
  // get data from previous frame
  // const maxFramesCountToSearch = Math.max(actualFrameId - framesCountToSearch, 0);
  for (let pfd = data.length - 1; pfd >= 0; --pfd) {
    const previousFrameData = data[pfd];
    // skip not actual frames
    if (previousFrameData.frame_id >= actualFrameId) continue;
    // stop search when some frames are not in array
    if (previousFrameData.pts < minTimeSecondsToSearchPts) break;
    const previousFaces = previousFrameData.Objects;
    // get face data with current face id
    const previousFace =
      previousFaces == null ? null : previousFaces.find((f) => f.uid === actualFace?.uid);

    if (previousFace?.emotions?.length > 0) {
      // found for this face - return emotions
      return previousFace.emotions;
    }
  }
  return actualFace?.emotions;
};

export function getMaxFromEmotions(emotions: any[]) {
  if (!emotions || emotions.length === 0)
    return null;
  let maxElIndex = 0;
  let maxElPercent = emotions[maxElIndex][1];
  for (let i = maxElIndex + 1; i < emotions.length; ++i) {
    if (maxElPercent < emotions[i][1]) {
      maxElPercent = emotions[i][1];
      maxElIndex = i;
    }
  }
  return emotions[maxElIndex];
}

// remove not clustered faces
// convert hr in statistics
export function convertStats(statistics: Data[], removeNotClusteredFaces: boolean = true, recalculateIfNotHereBP: boolean = false) : Data[] {
  return statistics.map((e) => {
    const objects: ObjectData[] = e.Objects;
    // remove not clustered faces
    const objectsA = removeNotClusteredFaces ? objects.filter((od) => (typeof od.vi === 'number') && od.vi >= 0) : objects;
    // convert hr inside objects
    const objectsB = objectsA.map((od: any) => {
      const newVal = (typeof od.hr === 'number') || !(od?.hr?.includes('%') && od?.hr !== 'N/A')
        ? `${parseInt(`${od.hr}`) + 60}`
        : od.hr;
      return {...od, hr: newVal};
    });
    // recalculate if not here bp
    const objectsC = !recalculateIfNotHereBP ? objectsB :
      objectsB.map((od: any) => {
        return {...od, bp: calculateIfNotHereBP(od)};
      });
    return {...e, Objects: objectsC};
  });
}

// based on 'controller' post_process_stats()
function calculateIfNotHereBP(od: any) {
    if ((typeof od?.hr === 'string') && od?.hr?.includes('%')) {
      return od.hr;
    } else {
      const hr = Number(od.hr);
      const rr = Number(od.rr);
      if (!isNaN(od.hr) && !isNaN(od.rr)) {
        const bp_0 = 110 + (hr * rr * 3) % 20  // 110-130
        const bp_1 = 70 + (3 * (hr + rr)) % 20  // 70-90
        return `${Math.round(bp_0)}/${Math.round(bp_1)}`;
      }
    }
    return 'N/A';
}

export const findFrameData = (
  statistics: Data[],
  actualFrame: Data,
  fromFrameId: number,
  windowsFromFrameId: number,
) => {
  const MaxSearchFrameId = Math.min(fromFrameId + windowsFromFrameId, actualFrame.frame_id);
  for (let pfd = 0; pfd < statistics.length; ++pfd) {
  // for (let pfd = data.length - 1; pfd >= maxFramesCountToSearch; --pfd) {
    const frameData = statistics[pfd];
    // skip not actual frames
    if (frameData.frame_id < fromFrameId) continue;
    // find first target frame
    if (fromFrameId <= frameData.frame_id && frameData.frame_id < MaxSearchFrameId)
      return frameData;
    // finish search
    if (frameData.frame_id > MaxSearchFrameId) break;
  }
  return actualFrame;
};
