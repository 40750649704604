import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { footerLogoSvg } from '../../img';
import { footerFacebookIconSvg, footerInstagramIconSvg, tiktokIconSvg } from '../../img/socials';
import { mobileMedia } from '../../theme';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 25,
  },
  main: {
    backgroundColor: '#040E9A',
    padding: '48px 0px 31px 0px',
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [mobileMedia]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  copyright: {
    backgroundColor: '#CAD6FF',
    padding: '26px 0px 17px 0px',
    fontSize: 13,
  },
  copyrightText: {
    textAlign: 'center',
    color: '#000',
    display: 'block',
  },
  contentWrapper: {
    maxWidth: 580,
    margin: '0 auto',
    padding: '0px 36px',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 7,
  },
  link: {
    background: 'transparent',
    fontSize: 16,
    marginBottom: 20,
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  logo: {
    [mobileMedia]: {
      marginBottom: 23,
    },
  },
  socials: {
    display: 'flex',
    alignItems: 'center',
  },
  social: {
    width: 25,
    marginRight: 15,
    '&:last-child': {
      marginRight: 0,
    },
    '& img': {
      width: 25,
      maxHeight: 25,
    },
    display: 'flex',
  },
}));

const socialLinks = [
  {
    icon: footerFacebookIconSvg,
    link: 'https://www.facebook.com/gleenrapp',
  },
  {
    icon: footerInstagramIconSvg,
    link: 'https://www.instagram.com/gleenrapp/',
  },
  {
    icon: tiktokIconSvg,
    link: 'https://www.tiktok.com/@gleenrapp',
  },
];

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.contentWrapper}>
          <div className={classes.mainContent}>
            <img src={footerLogoSvg} alt="" className={classes.logo} />
            <div className={classes.links}>
              <Link to="/privacy-policy" className={classes.link}>
                Privacy Policy
              </Link>
              <Link to="/terms-and-conditions" className={classes.link}>
                Terms & Conditions
              </Link>
            </div>
            <div className={classes.socials}>
              {socialLinks.map((l, i) => (
                <a
                  key={i}
                  href={l.link}
                  rel="noreferrer"
                  target="_blank"
                  className={classes.social}
                >
                  <img src={l.icon} alt="" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.copyright}>
        <div className={classes.contentWrapper}>
          <span className={classes.copyrightText}>
            © Copyright 2022 Gleenr | All Rights Reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
