
import { capitalize, makeStyles, Button, TextField, CircularProgress } from '@material-ui/core';
import cn from 'classnames';
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import { getAuthToken } from '../api';
import { onStartStreamingCam, onDataProcessStop } from '../api/upload';
import { PageWrapper } from '../components/PageWrapper';
import { userSelector } from '../store/userSlice';
import { setData, setDataMeanSincerity } from '../store/dataSlice';
import {
  setFpsVideo,
  setVideoWidth,
  setVideoHeight,
  setWidth,
  setHeight,
} from '../store/videoSlice';
import { mobileMedia } from '../theme';
import theme from '../theme';
import { checkDeviceIsMobile } from '../utils/checkDeviceIsMobile';
import { convertStats, faceWithPreviousFrameEmotionsOnly } from '../utils/handleStatistics';
import { makeToast } from '../utils/makeToast';
import VideoItem from '../components/VideoItem';
import { StoreState } from '../store/types';
import { IdentifiedFaces } from '../components/IdentifiedFaces';
import { fakeFacePng, sincerityHandPng } from '../img';
import { DropdownBlock } from '../components/DropdownBlock';
import { PlayerInfoDiagrams } from '../components/analysis-player';
import EmotionTable from '../components/EmotionTable';
import LineChart from '../components/PulseChart/LineChart';
import { LinearBarS } from '../components/LinearBar';


const LOGDBG = true;
const LOGDBGTRACE = false;

function to_log(t: any) {
  LOGDBG && console.log(t);
}

function to_log_trace(t: any) {
  LOGDBGTRACE && console.log(t);
}


const responsiveMedia = '@media(max-width:1115px)';
export { responsiveMedia as analysisPlayerResponsiveMedia };


const useStyles = makeStyles(() => ({
  pageRoot: {
    // maxWidth: '1270px',
    minHeight: '100vh',
  },
  uploadBtn: {
    margin: '0 auto',
    width: 300,
    [mobileMedia]: {
      width: 350,
      maxWidth: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  // webcam

  webcamRoot: {
    // background: 'red',
  },
  webcamRootPreviews: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
  webcamRootPreview: {
    width: '20%',
    minWidth: '100px',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'black !important',
  },
  switchCamStyles: {
    display: 'flex',
    margin: '20px auto',
  },
  buttons: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: 250,
  },
  youtube: {
    margin: 5,
    minWidth: 350,
    color: '#fff',
  },

  // preview

  rootPreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewCameraButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px',
  },
  previewCamera: {
    width: '10%',
    maxWidth: 75,
    marginRight: '5px',
    marginBottom: 5,
  },
  previewImage: {
    width: '100%',
    height: '100%',
    maxHeight: '50%',
    maxWidth: '85%',
    position: 'relative',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      minHeight: 150,
    },
  },
  img: {
    width: 'auto',
    height: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    minHeight: 100,
  },

  // faces
  facesWrapper: {
    margin: 25,
    [responsiveMedia]: {
      margin: 32,
    },
  },

  // sincerity
  infoSide: {
    flex: 1,
    [responsiveMedia]: {
      maxWidth: 'none',
    },
    marginBottom: 70,
  },
  infoTitle: {
    fontSize: 18,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoItem: {
    display: 'flex',
    '& h4': {
      marginRight: 15,
      fontSize: 16,
      fontWeight: 500,
    },
    '& span': {
      maxWidth: 305,
      display: 'block',
    },
    marginBottom: 30,
  },
  sincerityText: {
    fontSize: 16,
    '& p': {
      marginBottom: 16,
    },
  },

  // diagrams
  emotionsGraphics: {
    display: 'flex',
    [responsiveMedia]: {
      flexDirection: 'column',
      maxHeight: 'none',
    },
    [mobileMedia]: {
      padding: '0px 16px',
      marginBottom: 40,
    },
    marginBottom: 70,
    justifyContent: 'space-between',
  },
  emotionsSide: {
    flex: '0 1 45%',
    marginRight: 50,
    [responsiveMedia]: {
      flex: '100%',
      paddingBottom: 37,
      borderBottom: '1px solid #CCD6FC',
      marginBottom: 30,
      marginRight: 0,
    },
    '&:last-child': {
      marginRight: 0,
      [mobileMedia]: {
        marginBottom: 0,
      },
    },
  },
  graphicsSide: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },

  graphicsWrapper: {
    width: '100%',
    flex: 1,
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  chart: {
    overflow: 'hidden',
    [mobileMedia]: {
      padding: '0px 16px',
    },
  },
  tariffName: {
    marginBottom: 50,
    fontSize: 16,
    display: 'block',
  },
}));


export const VideoConstraintsDim = {
  width: 480,
  height: 370,
};


export const AnalysisWebcamPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  
  const { data, currentTime, currentFrame, fps, videoWidth, videoHeight, width, height } = useSelector((state: StoreState) => ({
    data: state.data.data,
    currentTime: state.video.currentTime,
    currentFrame: state.data.currentFrame,
    fps: state.video.fps,
    videoWidth: state.video.videoWidth,
    videoHeight: state.video.videoHeight,
    width: state.video.width,
    height: state.video.height,
  }));


  // web camera

  const camRef = useRef(null);
  const [camMode, setCamMode] = useState<string>('user');

  const [camRefs, setCamRefs] = useState([]);
  const [devices, setDevices] = useState([]);

  const [cameraFps, setCameraFps] = React.useState(5);

  const [cameraId, setCameraId] = useState(null);
  const [cameraStartTimeISOString, setCameraStartTimeISOString] = useState<string>();

  const isMobileDevice: boolean = checkDeviceIsMobile();
  const videoConstraintsHere: MediaStreamConstraints['video'] = { ...VideoConstraintsDim };
  isMobileDevice && (videoConstraintsHere.facingMode = camMode);
  !isMobileDevice && (videoConstraintsHere.deviceId = cameraId);

  const currentAlgorithm = "sincerity";

  const selectCamera = async (refNum: number) => {
    let camera: Webcam = camRef.current;

    if (!isMobileDevice) {
      camera = camRefs[refNum].current;
    }

    if (camera) {
      let imageSrc = camera.getScreenshot();
      if (imageSrc) {
        // remove space from data for request before
        // imageSrc = imageSrc.replaceAll('+', '%2B');
        const cameraStartTime = new Date().toISOString();
        let resp_status_code = 0;
        let resp_status_catch = false;
        try {
          const data = await onStartStreamingCam(imageSrc, cameraFps, currentAlgorithm,
            cameraStartTime
              .replaceAll('.', '')
              .replaceAll(':', '')
              .replaceAll('+', '')
              .replaceAll('-', ''));
          resp_status_code = data.status;
        } catch (e: any) {
          resp_status_code = e?.response?.status;
          resp_status_catch = true;
        }
        if (resp_status_code !== 200 || resp_status_catch) {
          if (resp_status_code === 403) {
            makeToast({message: "Login, please", type: 'error'});
            history.push('/login');
          } else {
            makeToast({message: "Can't start", type: 'error'});
          }
          return;
        }

        dispatch(setData([]));
        dispatch(setFpsVideo(cameraFps));
        setCameraId(isMobileDevice ? devices[0]?.deviceId : devices[refNum]?.deviceId);
        setCameraStartTimeISOString(cameraStartTime);
        frameListenerActive.current = true;
        setLoadingHere(true);
      }
    }
  };

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      const actualDevices = mediaDevices.filter((e: MediaDeviceInfo) => e.kind === 'videoinput');
      setDevices(actualDevices);
      // recreate array with links
      setCamRefs(Array(actualDevices.length).fill(React.createRef()));
    }, [setDevices]);

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);


  // preview

  const containerRef = React.useRef(null);
  const TESTING = ''; // TODO - change it - set some blank image
  const [data_img, setDataImg] = React.useState(TESTING);
  const [dataImgOnPage, setDataImgOnPage] = React.useState(null);
  const [previewMainHidden, setPreviewMainHidden] = React.useState(true);

  const [loadingHere, setLoadingHere] = React.useState(false);

  const [containerWidthHere, setContainerWidthHere] = React.useState(0);
  const [containerHeightHere, setContainerHeightHere] = React.useState(0);

  const [widnowSize, setWindowSize] = React.useState([0, 0]);
  const updateSize = () => setWindowSize([window.innerWidth, window.innerHeight]);
  function updateSizeForce() {
    if (containerRef.current) {
      setContainerWidthHere(containerRef.current.clientWidth);
      setContainerHeightHere(containerRef.current.clientHeight);
    }
  }
  React.useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // keep container width height on window resize
  React.useEffect(() => {
    if (containerRef.current && containerWidthHere != containerRef.current.clientWidth)
      setContainerWidthHere(containerRef.current.clientWidth);
    if (containerRef.current && containerHeightHere != containerRef.current.clientHeight)
      setContainerHeightHere(containerRef.current.clientHeight);
  }, [containerRef, widnowSize]);


  // handle web camera
  const webcamRef = React.useRef(null);
  const wcRecordingActiveSentMilSec = React.useRef(Date.now());
  const sendFrameIndex = React.useRef(1);
  const delay = fps > 0 ? 1000.0 / fps : 1000.0;
  const onUserMedia = () => {
    console.log('videoinput onUserMedia');
  };
  const onUserMediaError = (err: string | DOMException) => {
    console.log('videoinput onUserMediaError ' + err);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function sendNextFrame() {
to_log_trace(`sendNextFrame listen:${frameListenerActive.current} cameraId:${cameraId} i:${sendFrameIndex.current}`);
    if (!frameListenerActive.current) return;
    wcRecordingActiveSentMilSec.current = Date.now();
    if (webcamRef.current && cameraId !== '') {
      let imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        wSocketSend?.send(JSON.stringify({image: imageSrc, index: sendFrameIndex.current}));
        sendFrameIndex.current += 1;
      }
    }
    const sendDelay = Math.max(wcRecordingActiveSentMilSec.current + delay - Date.now(), 0);
to_log_trace('sendNextFrame ' + sendDelay);
    setTimeout(sendNextFrame, sendDelay);
  }

  const doOnDataProcessStop = async () => {
    try {
      try {
        const data = await onDataProcessStop();
        console.log('doOnDataProcessStop ' + data);
      } catch {
        makeToast({message: "Stop", type: 'error'});
      }
      // stop frames sending
      frameListenerActive.current = false;
      wSocketPreview?.close();
      setWSocketPreview(null);
      wSocketSend?.close();
      setWSocketSend(null);
      setLoadingHere(false);
      // go to play page with delay
      setTimeout(() => loadVideoData(), 1500);
      console.log('doOnDataProcessStop go to play');
    } catch (e) {
      console.log(`error ${JSON.stringify(e)}`);
    }
  };


  // loading

  const [wSocketPreview, setWSocketPreview] = React.useState(null);
  const [wSocketSend, setWSocketSend] = React.useState(null);

  const indexFrameOnClient = React.useRef(-1);
  const frameListenerActive = React.useRef(false);

  function getWebSocketUri(uri_env: string) {
console.log(`getWebSocketUri ${uri_env}`);
    let uri_actual = '';
    if (uri_env.startsWith('ws://') || uri_env.startsWith('wss://')) {
      uri_actual = uri_env;
    } else {
      const base_url = process.env.REACT_APP_BASE_URL;
console.log(`getWebSocketUri base_url ${base_url}`);
      if (base_url) {
        const prefix_http = 'http:';
        const prefix_https = 'https:';
        if (base_url.startsWith(prefix_http)) {
          uri_actual = 'ws:' + base_url.substr(prefix_http.length) + uri_env;
        } else if (base_url.startsWith(prefix_https)) {
          uri_actual = 'wss:' + base_url.substr(prefix_https.length) + uri_env;
        }
      } else {
        const loc = window.location;
console.log(`getWebSocketUri loc ${JSON.stringify(loc)}`);
        uri_actual = (loc.protocol === "https:" ? "wss:" : "ws:") + "//" + loc.host;
        if (uri_env.startsWith('/')) {
          uri_actual += uri_env;
        }
      }
    }
    return uri_actual;
  }

  const loadVideoData = React.useCallback(async () => {
    // doDataReset();
to_log('loadVideoData()');
  }, []);

  function frameDoneListen() {
    if (wSocketPreview?.onmessage) {
      wSocketPreview.send('getframeindex');
      setTimeout(frameDoneListen, 2000);
    }
  }

  function wSocketPreviewOnMessage(e: any) {
    const data = JSON.parse(e.data);

    if (data?.state === 'done') {
      console.log('socket preview on done');
      frameListenerActive.current = false;
      wSocketPreview?.close();
      setWSocketPreview(null);
      setLoadingHere(false);
      loadVideoData();
    }

    if (data && indexFrameOnClient.current !== data.index) {
      if (data.type === 'frameindex') {
        wSocketPreview?.send('getframedata');
      } else if (data.type === 'framedata') {
        if (data.image) {
          setDataImg(data.image);
          indexFrameOnClient.current = data.index;
        }
        if (data.stats) {
          const stats = convertStats(data?.stats || [], false, true);
console.log('data: ' + JSON.stringify(stats));
          dispatch(setData(stats));
          updateSizeForce();
        }
      }
    }
  }

  // init web socket preview
  React.useEffect(() => {
    if (wSocketPreview) {
to_log(`wSocketPreview init`);
      wSocketPreview.onopen = () => {
        console.log("preview connection on");
        wSocketPreview.onmessage = wSocketPreviewOnMessage;
        // start listening
        setTimeout(frameDoneListen, 1000);
      };
      wSocketPreview.onclose = () => {
        console.log("preview connection off");
        setLoadingHere(false);
        // use "onmessage = null" to stop frameDoneListen()
        wSocketPreview.onmessage = null;
      };
    }
  }, [wSocketPreview]);

  React.useEffect(() => {
to_log(`wSocketPreview create loadingHere:${loadingHere} wSocketPreview:${wSocketPreview} active:${frameListenerActive.current}`);
    if (loadingHere && !wSocketPreview) {
      const uri_actual = getWebSocketUri(process.env.REACT_APP_SOCKET_FRAME_PREVIEW);
      const queryToken = getAuthToken();
to_log(`wSocketPreview new ${uri_actual} t:${queryToken}`);
      setWSocketPreview(new WebSocket(`${uri_actual}?t=${queryToken}`));
    }
    if (!loadingHere && wSocketPreview) {
to_log(`setWSocketPreview close`);
      wSocketPreview.close();
      setWSocketPreview(null);
    }
  }, [wSocketPreview, loadingHere]);


  function wSocketSendOnMessage(e: any) {
    const data = JSON.parse(e.data);

    if (data?.state === 'done') {
      console.log('socket send on done');
      frameListenerActive.current = false;
      wSocketSend?.close();
      setWSocketPreview(null);
      setLoadingHere(false);
      loadVideoData();
    }
to_log_trace(`wSocketSend ${JSON.stringify(data)}`);
  }

  // init web socket send
  React.useEffect(() => {
to_log(`wSocketSend init wSocketSend:${wSocketSend}`);
    if (wSocketSend) {
      // init
      wSocketSend.onopen = () => {
        console.log("send connection on");
        wSocketSend.onmessage = wSocketSendOnMessage;
        // start sending
        // setTimeout(nextFrameSend, 1000);
      };
      wSocketSend.onclose = () => {
        console.log("send connection off");
        setLoadingHere(false);
        // use "onmessage = null" to stop nextFrameSend()
        // wSocketSend.onmessage = null;
      };
      setTimeout(sendNextFrame, 1000);
    }
  }, [wSocketSend]);

  React.useEffect(() => {
to_log(`wSocketSend create loadingHere:${loadingHere} wSocketSend:${wSocketSend} active:${frameListenerActive.current} cameraId:${cameraId}`);
    if (loadingHere && !wSocketSend && cameraId) {
      // create
      const uri_actual = getWebSocketUri(process.env.REACT_APP_SOCKET_FRAME_SEND);
      const queryToken = getAuthToken();
to_log(`wSocketSend new ${uri_actual} t:${queryToken}`);
      setWSocketSend(new WebSocket(`${uri_actual}?t=${queryToken}`));
    }
    if (!loadingHere && wSocketSend) {
to_log('wSocketSend close');
      wSocketSend.close();
      setWSocketSend(null);
    }
  }, [wSocketSend, loadingHere, cameraId]);


  const currentFrameId = data && data.length > 0 ? data[data.length - 1].frame_id : 0;
  let dataObject = { ...data.find((e: any) => e != null && e.frame_id === currentFrameId) };
  // fill emotions if need
  dataObject.Objects = dataObject?.Objects?.map((e: any) => {
    if (e && e.emotions && (!e.emotions || e.emotions.length == 0))
      return {
        ...e,
        emotions: faceWithPreviousFrameEmotionsOnly(data, currentFrameId, e, fps * 30),
      };
    return e;
  });


  // compute target pixels for absolute position
  // K = video width / video height
  // if K >= 1:
  //   w` = window width
  //   h` = window height / K
  // else:
  //   w` = window width * K
  //   h` = window height
  const videoAspectRatio = videoWidth && videoHeight ? videoWidth / videoHeight : 1;
  let targetContainerWidth =
    videoAspectRatio > 1 ? containerWidthHere : containerHeightHere * videoAspectRatio;
  let targetContainerHeight =
    videoAspectRatio > 1 ? containerWidthHere / videoAspectRatio : containerHeightHere;
  // and fit it
  if (targetContainerWidth > containerWidthHere) {
    const percent = containerWidthHere / targetContainerWidth;
    targetContainerWidth = containerWidthHere;
    targetContainerHeight = targetContainerHeight * percent;
  }
  if (targetContainerHeight > containerHeightHere) {
    const percent = containerHeightHere / targetContainerHeight;
    targetContainerHeight = containerHeightHere;
    targetContainerWidth = targetContainerWidth * percent;
  }
  // to center
  const targetContainerLeft = (containerWidthHere - targetContainerWidth) / 2;
  const targetContainerTop = (containerHeightHere - targetContainerHeight) / 2;
  // to fix positions
  const itemFaceBoxes = dataObject?.Objects?.map((item: any) => item?.face_box_ns || item?.face_box);


  const isUserManager = user.is_manager;
  const [showParamsAll, setShowParamsAll] = React.useState(!isUserManager);


  // diagrams

  const [currentFaceData, setCurrentFaceData] = useState<any>(null);
  const [currentFaceId, setCurrentFaceId] = useState<number | null>(null);

  const pulse = currentFaceData?.hr?.replace('NaN', '');

  const curEmotions = currentFaceData?.emotions && currentFaceData.emotions !== 0 ? currentFaceData.emotions : [];

  useEffect(() => {
    if (
      currentFaceId == null ||
      dataObject == null ||
      !dataObject?.Objects?.find((e: any) => e?.uid === currentFaceId)
    ) {
      setCurrentFaceId(dataObject?.Objects && dataObject?.Objects[0]?.uid);
    }
  }, [currentFaceId, dataObject]);

  useEffect(() => {
    setCurrentFaceData(dataObject?.Objects?.find((e) => e?.uid === currentFaceId));
  }, [dataObject, currentFaceId]);

  function getStatistic() {
    // Frame - here or not
    // FFF-FF-FF-FF
    const N = fps > 0 ? 10 * fps : 10 * 30;
    if (data) {
      const results: any[] = [];
      const begId = Math.max(0, currentFrameId - N),
        endId = Math.min(currentFrameId, data.length - 1);
      data.forEach((frame) => {
        if (frame != null && frame.frame_id > begId && frame.frame_id < endId) {
          // if previous frame was not found - fill with null
          // if was frame 4 and not 5 - skip
          // if was frame 4 and now 7 - add 2 null
          if (results.length > 0 && results[results.length - 1].frame_id + 1 !== frame.frame_id)
            for (
              let ii: number = results[results.length - 1].frame_id + 1;
              ii < frame.frame_id && results.length < N;
              ++ii
            )
              results.push({ frame_id: ii, Objects: null });
          results.push(frame);
        }
      });
      // if results length < N
      let additional = [];
      for (let i = results.length; i < N; ++i) additional.push(null);
      return additional.concat(results);
    } else return Array(N).fill(null);
  }


  function doDataReset() {
    dispatch(setData([]));
    dispatch(setDataMeanSincerity({}));
    dispatch(setFpsVideo(5));
    // scale for face boxes in stats
    const w = VideoConstraintsDim.width;
    const h = VideoConstraintsDim.height;
    const scale = h > 720 ? h / 720.0 : 1;
    dispatch(setVideoWidth(w / scale));
    dispatch(setVideoHeight(h / scale));
    dispatch(setWidth(w));
    dispatch(setHeight(h));
    // reset
    setDataImg('');
  }

  // clean data on reload
  useEffect(() => {
    doDataReset();
  }, []);


  // debug
  // for debug use actual array in setData(), actual jpeg in setDataImg() (and fps video height)
  // set data like in doDataReset()
  function doDataResetDebug() {
  }

  useEffect(() => {
    // doDataResetDebug();
  }, []);

  // proxy to avoid flickering
  useLayoutEffect(() => {
    setDataImgOnPage(data_img);
    setPreviewMainHidden(true);
    setTimeout(() => setPreviewMainHidden(false), 1);
  }, [data_img]);


  // buttons start stop

  const [buttonStartActive, setButtonStartActive] = useState(true);
  const [buttonStopActive, setButtonStopActive] = useState(true);

  function doClickButtonStart() {
    if (buttonStartActive) {
      setButtonStartActive(false);
      selectCamera(0);
      setTimeout(() => setButtonStartActive(true), 300);
    }
  }

  function doClickButtonStop() {
    if (buttonStopActive) {
      setButtonStopActive(false);
      doOnDataProcessStop();
      setTimeout(() => setButtonStopActive(true), 300);
    }
  }


  return (
    <PageWrapper rootClassName={classes.pageRoot}>
      <div className={classes.webcamRoot} hidden={loadingHere}>
        {/* desktop */}
        {!isMobileDevice &&
          devices.map((device, ind) => (
            <div key={device.deviceId} className={classes.webcamRootPreviews}>
              <Webcam
                audio={false}
                videoConstraints={{ ...VideoConstraintsDim, deviceId: device.deviceId }}
                className={classes.webcamRootPreview}
                screenshotFormat="image/jpeg" // important
                forceScreenshotSourceSize={true} // important
                ref={camRefs[ind]}
                onClick={() => selectCamera(ind)}
              />
              <div>{device.label || `Device ${ind + 1}`}</div>
            </div>
          ))}
        {/* mobile */}
        {/* Only 1 camera allowed */}
        {isMobileDevice && (
          <div className={classes.webcamRootPreviews}>
            <Webcam
              audio={false}
              videoConstraints={{ ...VideoConstraintsDim, facingMode: camMode }}
              className={classes.webcamRootPreview}
              screenshotFormat="image/jpeg" // important
              forceScreenshotSourceSize={true} // important
              ref={camRef}
            />
          </div>
        )}
        {isMobileDevice && (
          <Button
            onClick={() => {
              if (camMode === 'user') {
                setCamMode('environment');
              }
              if (camMode === 'environment') {
                setCamMode('user');
              }
            }}
            color="primary"
            variant="contained"
            className={classes.switchCamStyles}
          >
            Switch cam
          </Button>
        )}
        <div className={classes.buttons} style={{ width: '100%' }}>
          <TextField
            type="number"
            style={{ flex: 1, minWidth: 50 }}
            value={cameraFps}
            onChange={(e) => setCameraFps(parseInt(e.target.value))}
            color="primary"
            className={classes.youtube}
            size="medium"
            variant="outlined"
            label="Frame per second"
            InputLabelProps={{
              style: {
                color: 'black',
                fontSize: 15,
              },
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
              style: {
                color: 'black',
              },
            }}
          />
        </div>
      </div>

      <div hidden={!loadingHere} style={{margin: '5px', width: '100%'}}>
        <CircularProgress className={classes.uploadBtn} color="secondary" size={36} />
      </div>
      <div hidden={loadingHere} style={{margin: '5px'}}>
        <button
            className={cn(buttonStartActive ? 'primary-btn' : 'secondary-btn', classes.uploadBtn)}
            style={{ marginTop: 'auto', position: 'relative' }}
            onClick={doClickButtonStart}
            disabled={!buttonStartActive}
        >
            {capitalize(t('start'))}
        </button>
      </div>
      <div style={{margin: '5px'}}>
        <button
          className={cn(buttonStopActive ? 'primary-btn' : 'secondary-btn', classes.uploadBtn)}
          onClick={doClickButtonStop}
          disabled={!buttonStopActive}
        >
          {capitalize(t('stop'))}
        </button>
      </div>

      <div className={classes.rootPreview}>
        {loadingHere && cameraId !== '' && (
          <div className={classes.previewCameraButton}>
            <div className={classes.previewCamera}>
              <Webcam
                audio={false}
                width={'100%'}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                forceScreenshotSourceSize={true} // important
                onUserMedia={onUserMedia}
                onUserMediaError={onUserMediaError}
                videoConstraints={videoConstraintsHere}
              />
            </div>
          </div>
        )}

        <div ref={containerRef} className={classes.previewImage}>

          {data_img !== '' && (
            <img
              alt="demo"
              className={classes.img}
              src={`data:image/jpeg;base64,${dataImgOnPage}`}
              hidden={previewMainHidden}
              onLoad={(image) => {
                if (image.currentTarget.naturalWidth !== videoWidth) {
                  dispatch(setVideoWidth(image.currentTarget.naturalWidth));
                }
                if (image.currentTarget.naturalHeight !== videoHeight) {
                  dispatch(setVideoHeight(image.currentTarget.naturalHeight));
                }
              }}
            />
          )}
          {data_img !== '' && (
            <img
              alt="demo"
              className={classes.img}
              src={`data:image/jpeg;base64,${dataImgOnPage}`}
              hidden={!previewMainHidden}
              onLoad={(image) => {
                if (image.currentTarget.naturalWidth !== videoWidth) {
                  dispatch(setVideoWidth(image.currentTarget.naturalWidth));
                }
                if (image.currentTarget.naturalHeight !== videoHeight) {
                  dispatch(setVideoHeight(image.currentTarget.naturalHeight));
                }
              }}
            />
          )}

          {/* stats */}
          <div
            style={{
              position: 'absolute',
              width: targetContainerWidth,
              height: targetContainerHeight,
              top: targetContainerTop + 'px',
              left: targetContainerLeft + 'px',
              pointerEvents: 'none',
              zIndex: 2,
              padding: '5px',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              alignContent: 'space-between',
            }}
          >
            {dataObject?.Objects?.map((item: any, index: any) => {
              const face_box = item?.face_box_ns || item?.face_box;
              return (
                <VideoItem
                  key={item?.uid}
                  emotions={item?.emotions}
                  positions={face_box}
                  isPositionsNotScaled={!!item?.face_box_ns}
                  colorRgb={item?.color_rgb}
                  hr={item?.hr || ''}
                  rr={item?.rr}
                  eyes={item?.gazes?.eac}
                  bp={item?.bp}
                  sincerity={item?.sincerity}
                  showParamsAll={showParamsAll}
                  drawInListIndex={index + 1} // set actual index to fix item position
                  drawInListFaceBoxes={itemFaceBoxes}
                  itemType={'statsonly'}
                />
              );
            })}
          </div>
          {/* face boxes */}
          <div
            style={{
              position: 'absolute',
              width: targetContainerWidth,
              height: targetContainerHeight,
              top: targetContainerTop + 'px',
              left: targetContainerLeft + 'px',
              pointerEvents: 'none',
              zIndex: 2,
            }}
          >
            {dataObject?.Objects?.map((item: any, index: any) => {
              const face_box = item?.face_box_ns || item?.face_box;
              return (
                <VideoItem
                  key={item?.uid}
                  emotions={item?.emotions}
                  positions={face_box}
                  isPositionsNotScaled={!!item?.face_box_ns}
                  colorRgb={item?.color_rgb}
                  hr={item?.hr || ''}
                  rr={item?.rr}
                  eyes={item?.gazes?.eac}
                  bp={item?.bp}
                  sincerity={item?.sincerity}
                  showParamsAll={showParamsAll}
                  drawInListIndex={index + 1} // set actual index to fix item position
                  drawInListFaceBoxes={null}
                  itemType={'boxonly'}
                />
              );
            })}
          </div>
        </div>
      </div>

      {!!data_img && (
        <div>
          {isUserManager
          ? <div><label className="switch switch-around" >
              <input type="checkbox" onClick={() => setShowParamsAll(!showParamsAll)} checked={showParamsAll} readOnly />
              <span className="slider round"></span>
              <div className='switch title'>&nbsp;Show emotions</div>
            </label></div>
          : <></>
          }

          <div className={classes.facesWrapper}>
            <IdentifiedFaces
              faces={
                dataObject?.Objects?.map((d) => ({
                  id: d.uid,
                  image: fakeFacePng,
                })) || []
              }
              selectedFaceId={currentFaceId}
              setSelectedFaceId={setCurrentFaceId}
            />
          </div>

          <div className={classes.infoSide}>
            {user.planName && <span className={classes.tariffName}>{user.planName}</span>}
            <div className={classes.infoTitle}>
              <h3>{capitalize(t('summary'))}</h3>
            </div>
            <div className={classes.infoItem}>
              <h4>{capitalize(currentFaceData?.sincerity || 0 ? t('sincerity') : t('insincerity'))}</h4>
              <div style={{ flex: 1 }}>
                <LinearBarS
                  value={currentFaceData?.sincerity || 0}
                  height={21}
                  backgroundImage={`url(${sincerityHandPng})`}
                  colorlessFilter={false}
                  thumbStyles={{
                    padding: 6,
                  }}
                />
              </div>
            </div>
            <div className={classes.sincerityText}>
              <p>
                If Sincerity is low,
                the subject scored low on the sincerity index,
                which suggests that throughout the video,
                the subject was likely not being truthful for most of the analyzed period.
              </p>
              <p>
                If Sincerity is not low,
                the subject scored neut on the sincerity index,
                which suggests that throughout the video, the subject's sincerity is inconclusive.
              </p>
            </div>
          </div>

          <div className={classes.emotionsGraphics}>
            <DropdownBlock
              title={capitalize(t('physical_display'))}
              wrapperClassName={classes.emotionsSide}
            >
              <PlayerInfoDiagrams currentFaceData={currentFaceData} pulse={pulse} />
            </DropdownBlock>
            <DropdownBlock
              title={capitalize(t('emotional_display'))}
              wrapperClassName={classes.emotionsSide}
            >
              <EmotionTable emotions={curEmotions} />
            </DropdownBlock>
          </div>
          <div className={classes.chart}>
            <DropdownBlock
              title={capitalize(t('photoplethysmogram'))}
              wrapperClassName={classes.graphicsSide}
            >
              <div className={classes.graphicsWrapper}>
                <LineChart dataSets={getStatistic()} color="red" id={currentFaceData?.uid} />
              </div>
            </DropdownBlock>
          </div>
        </div>
      )}

    </PageWrapper>
  );
};
