import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { addVideoSvg, helpSvg, homeSvg, profileSvg, playerSvg } from '../../img/header';
import { mobileMedia } from '../../theme';
import { NavbarItemsDropdown } from './ItemsDropdown';
import { getAuthToken, decodeToken } from '../../api';
import cn from 'classnames';

export const MOBILE_NAVBAR_HEIGHT = 84;

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    [mobileMedia]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      zIndex: 99,
      background: '#f5f7ff',
      width: '100vw',
      padding: '0px 82px',
      justifyContent: 'space-between',
      height: MOBILE_NAVBAR_HEIGHT,
    },
    '@media(max-width:680px)': {
      padding: '0px 25px',
    },
  },
  rootDesktopHidden: {
    display: 'none',
    [mobileMedia]: {
      display: 'flex',
    },
  },
  tabWrapper: {
    display: 'inline-flex',
    position: 'relative',
    marginRight: 35,
    '&:last-child': {
      marginRight: 0,
    },
    [mobileMedia]: {
      marginRight: 5,
    },
  },
  tab: {
    color: 'inherit',
    borderBottom: '2px solid transparent',
    width: '100%',
    cursor: 'pointer',
    fontSize: 16,
    padding: '0px 8px',
    '&:hover': {
      borderColor: '#04129A',
      [mobileMedia]: {
        borderColor: 'transparent',
      },
    },
    display: 'flex',
    alignItems: 'center',
    [mobileMedia]: {
      flexDirection: 'column',
      fontSize: 12,
    },
  },
  tabActive: {
    borderBottom: '2px solid #04129A',
    color: '#04129A',
    [mobileMedia]: {
      borderColor: 'transparent',
    },
  },
  tabLabel: {
    [mobileMedia]: {
      order: 2,
      textAlign: 'center',
    },
    '@media(max-width:600px)': {
      display: 'none',
    },
  },
  tabIcon: {
    width: 34,
    marginLeft: 12,
    [mobileMedia]: {
      marginLeft: 0,
    },
  },
  hiddenTabIcon: {
    display: 'none',
    [mobileMedia]: {
      display: 'block',
    },
  },
  arrow: {
    marginLeft: 4,
  },

  mobileMenu: {
    backgroundColor: '#fff',
  },
}));

type Dropdown = 'language' | 'account';

export type OpenedDropdownType = Dropdown | '';

export type TabType = {
  onClick: () => any;
  label: string;
  link?: string;
  dropdownName?: Dropdown;
  icon?: string;
  onlyMobileIcon: boolean;
};

type Props = {
  hiddenOnDesktop?: boolean;
};

const Navbar: React.FC<Props> = ({ hiddenOnDesktop }) => {
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();

  const [openedDropdown, setOpenedDropdown] = useState<OpenedDropdownType>('');

  const onDropdownClick = useCallback(
    (dropdownName: Dropdown) => {
      if (openedDropdown === dropdownName) {
        setOpenedDropdown('');
      } else {
        setOpenedDropdown(dropdownName);
      }
    },
    [openedDropdown]
  );

  const homeUrl = decodeToken(getAuthToken())?.sub || '/';

  const tabs = useMemo<TabType[]>(
    () => [
      {
        onClick: () => window.location.replace(`${homeUrl}`),
        label: t('home_page'),
        link: homeUrl,
        icon: homeSvg,
        onlyMobileIcon: true,
      },
      {
        onClick: () => history.push('/catalog'),
        label: t('my_videos'),
        link: '/catalog',
        icon: playerSvg,
        onlyMobileIcon: true,
      },
      {
        onClick: () => history.push('/analysis'),
        label: t('upload_a_video'),
        link: '/analysis',
        icon: addVideoSvg,
        onlyMobileIcon: true,
      },
      {
        onClick: () => {},
        label: t('help'),
        link: '/',
        icon: helpSvg,
        onlyMobileIcon: true,
      },
      {
        onClick: () => onDropdownClick('account'),
        label: t('my_account'),
        dropdownName: 'account',
        icon: profileSvg,
        onlyMobileIcon: false,
      },
    ],
    [history, homeUrl, onDropdownClick, t]
  );

  const tabIsActive = (tab: TabType): boolean => {
    if (!tab.link) return false;

    if (tab.link === history.location.pathname) return true;

    return false;
  };

  return (
    <div
      className={cn(classes.root, {
        [classes.rootDesktopHidden]: hiddenOnDesktop,
      })}
    >
      {tabs.map((tab, i) => (
        <div key={i} className={classes.tabWrapper}>
          <div
            onClick={tab.onClick}
            className={`${classes.tab} ${tabIsActive(tab) ? classes.tabActive : ''}`}
          >
            <span className={classes.tabLabel}> {t(tab.label)}</span>
            {tab.icon ? (
              <img
                src={tab.icon}
                className={cn(classes.tabIcon, {
                  [classes.hiddenTabIcon]: tab.onlyMobileIcon,
                })}
                alt=""
              />
            ) : null}
          </div>
          <NavbarItemsDropdown tab={tab} openedDropdown={openedDropdown} logoutUrl={homeUrl} />
        </div>
      ))}
    </div>
  );
};

export default Navbar;
