export const getMaxOccuringItems = (array: number[], itemsCount: number): [string, number][] => {
  const itemsCounts: Record<number, number> = {};

  for (let i = 0; i < array.length; i++) {
    const currentItemCounts = itemsCounts[array[i]];
    if (!currentItemCounts) {
      itemsCounts[array[i]] = 1;
    } else {
      itemsCounts[array[i]] += 1;
    }
  }

  return Object.entries(itemsCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, itemsCount);
};
