import { AxiosRequestConfig } from 'axios';
import { api, getAuthHeaders } from '.';
import axios from 'axios';

export const uploadFile = ({
  file,
  onProgressChange,
  config = {},
}: {
  file: File;
  onProgressChange?: (progress: number) => any;
  config?: AxiosRequestConfig;
}) => {
  const data = new FormData();
  data.append('file', file);

  return api.post('/upload/file', data, {
    onUploadProgress(progressEvent) {
      const progress = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
      onProgressChange(progress);
    },
    ...config,
  });
};

export const uploadLink = (link: string) => {
  return api.post('/upload/link', {
    link,
  });
};

export const onStartStreamingCam = (image: any, fps: any, algorithmType: string, startTimeSimplified: string) => {
  // local dev
  if (!!process.env.REACT_APP_RECOGNIZER_DEV_HOST)
    return axios.create({baseURL: process.env.REACT_APP_RECOGNIZER_DEV_HOST, ...getAuthHeaders()})
      .post(`/data/process/wc/reset?fps=${fps}&scenario=${algorithmType}&startTime=${startTimeSimplified}`, image, {});

  // default server dev release
  return api.post(`/data/process/wc/reset?fps=${fps}&scenario=${algorithmType}&startTime=${startTimeSimplified}`, image, {});
};

export const onDataProcessStop = () => {
  // local dev
  if (!!process.env.REACT_APP_RECOGNIZER_DEV_HOST)
    return axios.create({baseURL: process.env.REACT_APP_RECOGNIZER_DEV_HOST, ...getAuthHeaders()})
      .get('/data/process/stop');

  // default server dev release
  return api.get('/data/process/stop');
};
