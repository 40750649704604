import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#04009a',
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '14px',
    color: '#04009a',
    marginLeft: 6,
  },
  iconWrapper: {
    marginRight: 10,
    display: 'flex',
  },
});

interface LineInfoProps {
  icon: ReactElement;
  data?: number | string;
}

const LineInfo: React.FC<LineInfoProps> = ({ icon, data }: LineInfoProps) => {
  const classes = useStyles();
  const titleText = String(data).includes('N/A') || String(data).includes('NaN') ? '-' : data;

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>{icon}</div>
      <Typography className={classes.title} variant="subtitle1">
        {titleText}
      </Typography>
    </div>
  );
};

export default LineInfo;
