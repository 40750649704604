import { makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  diagramBloodPressureSvg,
  diagramBreathSvg,
  diagramEyeSvg,
  diagramPulseSvg,
} from '../../img';
import getEyesValue from '../../utils/getEyesValue';

const ICON_WIDTH = 20;
const ICON_MARGIN_RIGHT = 12;

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    marginBottom: 15,
  },
  itemTop: { display: 'flex', alignItems: 'center' },
  text: {
    fontSize: 16,
    marginRight: 10,
  },
  icon: {
    marginRight: ICON_MARGIN_RIGHT,
    width: ICON_WIDTH,
  },
  value: {
    fontSize: 16,
    fontWeight: 700,
    color: '#04129A',
  },
  description: {
    display: 'block',
    marginTop: 10,
    marginLeft: ICON_WIDTH + ICON_MARGIN_RIGHT,
  },
}));

type Props = {
  currentFaceData: any;
  pulse: number;
};

export const PlayerInfoDiagrams: React.FC<Props> = ({ currentFaceData, pulse }) => {
  const { t } = useTranslation();

  const eyesData = currentFaceData?.gazes?.eac;
  const breathData = currentFaceData?.rr?.replace('N/A', '') || '';

  const eyesValue = getEyesValue(eyesData, t);
  const bloodPressure =
    currentFaceData?.bp === '-' ? '' : currentFaceData?.bp?.replace('N/A', '') || '';

  const classes = useStyles();

  const items = useMemo(() => {
    return [
      {
        icon: diagramEyeSvg,
        title: t('oculomotor_movements'),
        value: eyesValue,
        description: '',
      },
      {
        icon: diagramBreathSvg,
        title: t('breath'),
        value: !breathData
          ? '-'
          : String(breathData).includes('%')
          ? breathData
          : `${breathData}/${t('min')}`,
        description: '',
      },
      {
        icon: diagramPulseSvg,
        title: t('pulse'),
        value: !pulse || String(pulse).includes('%') ? pulse : `${pulse} ${t('bpm')}`,
        description: '',
      },
      {
        icon: diagramBloodPressureSvg,
        title: t('blood_pressure'),
        value:
          !bloodPressure || String(bloodPressure).includes('%')
            ? bloodPressure
            : `${bloodPressure} ${t('mm_hg')}`,
        description: '',
      },
    ];
  }, [bloodPressure, breathData, eyesValue, pulse, t]);

  return (
    <div className={classes.root}>
      {items.map((it, i) => (
        <div key={i} className={classes.item}>
          <div className={classes.itemTop}>
            <img className={classes.icon} src={it.icon} alt="" />
            <span className={classes.text}>{it.title}:</span>
            <span className={classes.value}>{it.value || '-'}</span>
          </div>
          <span className={classes.description}>{it.description}</span>
        </div>
      ))}
    </div>
  );
};
