import { api } from '.';

type GetListParams = {
  beg?: number;
  end?: number;
};

export const getCatalogList = ({ beg, end }: GetListParams = {}) => {
  let query = '';

  if (typeof beg === 'number' && typeof end === 'number') {
    query = `?beg=${beg}&end=${end}`;
  }

  return api.get('/catalog/list' + query);
};

export const getCatalogItem = async (fileId: string) => {
  const { data } = await api.get('/catalog/list');

  return data.list.find((i: any) => i.fileid === fileId);
};

export const deleteCatalogItem = async (fileId: string) => {
  return api.get(`/processed/remove/${fileId}`);
};
