import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OpenedDropdownType, TabType } from '.';
import { logoutUser } from '../../api/auth';
import { mobileMedia } from '../../theme';

const useStyles = makeStyles(() => ({
  dropdown: {
    position: 'absolute',
    top: '110%',
    left: 0,
    background: '#fff',
    zIndex: 66,
    [mobileMedia]: {
      top: '-150%',
    },
  },
  dropdownButton: {
    fontSize: 14,
    lineHeight: '16px',
    color: 'inherit',
    border: 'none',
    backgroundColor: 'transparent',
    padding: '8px 16px',
    textAlign: 'center',
    cursor: 'pointer',
    width: '100%',
    borderRadius: 3,
    '&:hover': {
      color: '#04129A',
    },
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginLeft: 5,
    },
  },
  listIcon: {
    transform: 'rotate(-180deg)',
  },
  withDropdown: {
    '&:hover': {
      '& div:nth-child(2)': {
        display: 'block',
      },
    },
    position: 'relative',
  },
  hiddenDropdown: {
    display: 'none',
    position: 'absolute',
    top: '100%',
    backgroundColor: '#fff',
    width: '100%',
  },
}));

type Props = {
  tab: TabType;
  openedDropdown: OpenedDropdownType;
  logoutUrl: string;
};

export const NavbarItemsDropdown: React.FC<Props> = ({ tab, openedDropdown, logoutUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (tab.dropdownName !== openedDropdown) return null;

  if (openedDropdown === 'account') {
    return (
      <div className={classes.dropdown}>
        <button
          onClick={async () => {
            try {
              await logoutUser();
            } catch (error) {
              console.log(error);
            } finally {
              window.location.replace(`${logoutUrl}`);
            }
          }}
          className={classes.dropdownButton}
        >
          {t('logout')}
        </button>
      </div>
    );
  }

  return null;
};
