import { capitalize, makeStyles } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVideoOptions } from '../api/player';
import { getReportData } from '../api/report';
import { DropdownBlock } from '../components/DropdownBlock';
import { IdentifiedFaces } from '../components/IdentifiedFaces';
import { PageWrapper } from '../components/PageWrapper';
import {
  BreathBlock,
  EmotionsBlock,
  EyeBlock,
  PulseBlock,
  SincerityBlock,
} from '../components/report';
import { PdfReport } from '../components/report/PdfReport';
import { PrintToPdfBtn } from '../components/report/PrintToPdfBtn';
import { SincerityBlockData } from '../components/report/SincerityBlock';
import { fakeFacePng } from '../img';
import { pdfIsExportingSelector, setPdfIsExporting } from '../store/report';
import { mobileMedia } from '../theme';
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  wrapper: {},
  wrapperExporting: {
    position: 'absolute',
    top: '0',
    background: '#fff',
    width: '1400px',
    padding: '15px 78px',
    opacity: '1',
  },
  header: {
    background: '#F5F7FF;',
    padding: '16px 52px',
    [mobileMedia]: {
      padding: '32px 16px',
    },
  },
  block: {
    marginTop: 32,
    paddingBottom: 38,
    borderBottom: '1px solid #CCD6FC',
    '&:last-child': {
      borderBottom: 'none',
    },
    '@media(max-width:1200px)': {
      height: 'auto',
    },
    overflow: 'hidden',
    [mobileMedia]: {
      margin: '0px 16px',
      marginTop: 32,
      paddingBottom: 16,
    },
  },
  blockExporting: {
    borderBottom: 'none',
  },
  blockTitle: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 16,
  },
  blockTitleExporting: {
    fontSize: 28,
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  blockContent: {},
  exportingBlocks: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  exportingBlock: {
    flex: '0 0 45%',
    marginRight: 70,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export const calculateEmotionTotalValue = (emotion: string, data: Array<[number, string]>) => {
  let total = 0;

  data.forEach((d: any, i: number) => {
    if (d[1] === emotion) {
      if (i === 0) {
        total += d[0];
      } else {
        total += d[0] - data[i - 1][0];
      }
    }
  });

  return +Number(total).toFixed(1);
};

export const uniquePieData = (data: [string, number][]) => {
  return Array.from(new Map(data.map((item) => [item[0], item])).values());
};

export const ReportPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [videoOptions, setVideoOptions] = useState({ fps: 0 });

  const pdfIsExporting = useSelector(pdfIsExportingSelector);

  const [reportData, setReportData] = useState([]);
  const [selectedFaceId, setSelectedFaceId] = useState(0);
  const currentFaceReportData: any = useMemo(() => {
    const data = reportData.find((d) => d.uid === selectedFaceId);

    return data || {};
  }, [reportData, selectedFaceId]);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setIsLoading(true);
        const [{ data: reportData }, { data: videoData }] = await Promise.all([
          getReportData(id),
          getVideoOptions(id),
        ]);

        const reportInfo = reportData?.report;

        if (reportInfo) {
          setReportData(reportInfo);
          setSelectedFaceId(reportInfo[0].uid);
        }

        if (videoData) {
          setVideoOptions({ fps: parseInt(videoData.fps) || 0 });
        }
      } catch (error) {
        console.log(error);
      } finally {
        // timeout for render charts
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    };

    fetchReportData();
  }, [id]);

  let preparedFaceData = useMemo(() => {
    let result: any = {};
    for (let key in currentFaceReportData) {
      const currentValue = currentFaceReportData[key];
      // filter every array field
      if (Array.isArray(currentValue)) {
        result[key] = currentValue
          .filter((d) => d[0] % videoOptions.fps === 0)
          .map((d) => [Math.floor(d[0] / videoOptions.fps), d[1]]);
      } else {
        result[key] = currentValue;
      }
    }

    return result;
  }, [currentFaceReportData, videoOptions.fps]);

  const sincerityData: SincerityBlockData = preparedFaceData?.sincerity || [];

  const beforeExport = async () => {
    dispatch(setPdfIsExporting(true));
    await new Promise((res) => {
      setTimeout(() => {
        res('');
      }, 500);
    });
  };

  const afterExport = () => {
    dispatch(setPdfIsExporting(false));
  };

  const dropdownBlockDefaultProps = {
    titleClassName: cn(classes.blockTitle, {
      [classes.blockTitleExporting]: pdfIsExporting,
    }),
    wrapperClassName: cn(classes.block, {
      [classes.blockExporting]: pdfIsExporting,
    }),
    hideDropdownBtn: pdfIsExporting,
  };

  const reportContent = (
    <div className={classes.wrapper}>
      <header className={classes.header}>
        <IdentifiedFaces
          faces={reportData.map((d) => ({
            id: d.uid,
            image: fakeFacePng,
          }))}
          faceSize={100}
          selectedFaceId={selectedFaceId}
          setSelectedFaceId={setSelectedFaceId}
        />
      </header>
      <DropdownBlock {...dropdownBlockDefaultProps} title={capitalize(t('sincerity'))}>
        <div className={classes.blockContent}>
          <SincerityBlock data={sincerityData || []} />
        </div>
      </DropdownBlock>
      {pdfIsExporting ? (
        <div className={classes.exportingBlocks}>
          <div className={classes.exportingBlock}>
            <DropdownBlock {...dropdownBlockDefaultProps} title={capitalize(t('emotions'))}>
              <div className={classes.blockContent}>
                <EmotionsBlock data={preparedFaceData.emotions || []} />
              </div>
            </DropdownBlock>
          </div>
          <div className={classes.exportingBlock}>
            <DropdownBlock
              {...dropdownBlockDefaultProps}
              title={capitalize(t('oculomotor_movements'))}
            >
              <div className={classes.blockContent}>
                <EyeBlock data={preparedFaceData.eyes || []} />
              </div>
            </DropdownBlock>
          </div>
        </div>
      ) : (
        <>
          <DropdownBlock {...dropdownBlockDefaultProps} title={capitalize(t('pulse'))}>
            <div className={classes.blockContent}>
              <PulseBlock data={preparedFaceData.pulse || [[0, 0]]} />
            </div>
          </DropdownBlock>
          <DropdownBlock {...dropdownBlockDefaultProps} title={capitalize(t('breath'))}>
            <div className={classes.blockContent}>
              <BreathBlock data={preparedFaceData.breath || [[0, 0]]} />
            </div>
          </DropdownBlock>
        </>
      )}
      {pdfIsExporting ? (
        <div className={classes.exportingBlocks}>
          <div className={classes.exportingBlock}>
            <DropdownBlock {...dropdownBlockDefaultProps} title={capitalize(t('pulse'))}>
              <div className={classes.blockContent}>
                <PulseBlock data={preparedFaceData.pulse || [[0, 0]]} />
              </div>
            </DropdownBlock>
          </div>
          <div className={classes.exportingBlock}>
            <DropdownBlock {...dropdownBlockDefaultProps} title={capitalize(t('breath'))}>
              <div className={classes.blockContent}>
                <BreathBlock data={preparedFaceData.breath || [[0, 0]]} />
              </div>
            </DropdownBlock>
          </div>
        </div>
      ) : (
        <>
          <DropdownBlock
            {...dropdownBlockDefaultProps}
            title={capitalize(t('oculomotor_movements'))}
          >
            <div className={classes.blockContent}>
              <EyeBlock data={preparedFaceData.eyes || []} />
            </div>
          </DropdownBlock>
          <DropdownBlock {...dropdownBlockDefaultProps} title={capitalize(t('emotions'))}>
            <div className={classes.blockContent}>
              <EmotionsBlock data={preparedFaceData.emotions || []} />
            </div>
          </DropdownBlock>
        </>
      )}
    </div>
  );

  return (
    <PageWrapper showProgress={isLoading || pdfIsExporting} loadContentInBackground>
      <PrintToPdfBtn
        wrapperSelector={`.${classes.wrapperExporting}`}
        beforeExport={beforeExport}
        afterExport={afterExport}
      />
      {pdfIsExporting ? (
        <PdfReport wrapperClassName={classes.wrapperExporting}>{reportContent}</PdfReport>
      ) : (
        reportContent
      )}
    </PageWrapper>
  );
};
