import React from 'react';
import { PageWrapper } from '../components/PageWrapper';
import { PrivacyTextContent } from '../components/privacy-and-terms';
import { PrivacyBlock } from '../components/privacy-and-terms/TextContent';

const blocks: Array<PrivacyBlock> = [
  {
    text: 'These Terms and Conditions of Use (“Agreement”) is a legal agreement between you and Gleenr Inc., doing business as, gleenr and, hereinafter referred to as (the “Website Owner”), the owner, and the developer of https://gleenr.app (the “Website”). By accessing any service provided on the Website, you become a client (“Client”) and you agree to be bound by  the terms (the “Terms”) set forth in this Agreement if you remain the Client.',
  },
  {
    text: 'YOU MUST BE AT LEAST 13 YEARS OF AGE TO ACCESS ANY SERVICE OF THE WEBSITE, AND MUST BE AT LEAST 18 YEARS OF AGE TO ACCESS AND USE ANY ”ADULT” DESIGNATED PORTIONS OF THE WEBSITE. IF YOU ARE 13 YEARS OR YOUNGER, DO NOT USE THIS WEBSITE FOR ANY PURPOSE AS IT IS NOT INTENDED FOR USE BY CHILDREN. IF YOU DO NOT AGREE TO THE TERMS, PLEASE DO NOT REGISTER FOR THE WEBSITE OR ANY OF THE WEBSITE’S SERVICES.',
    type: 'warning',
  },
  { text: 'The Terms are subject to change at any time, effective upon notice to you.' },
  {
    text: 'BY CLICKING THE “I AGREE” BUTTON, YOU AGREE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS. THE MOST CURRENT VERSION OF THE TERMS, WHICH SUPERSEDES ALL PREVIOUS VERSIONS, CAN BE REVIEWED BY GOING TO HTTPS://GLEENR.APP/TERMS. THE WEBSITE OWNER RESERVES THE RIGHT TO CHANGE THE TERMS AT ANY TIME AND WITHOUT NOTICE TO YOU.',
    type: 'warning',
  },
  { text: '1. Limitations of Liability and Indemnification.', type: 'title' },
  {
    text: 'THE SERVICES PROVIDED BY THE WEBSITE ARE PROVIDED “AS IS” IN THE BROADEST INTERPRETATION OF THE TERM.',
    type: 'warning',
  },
  {
    text: 'By using any services provided by the Website, you agree that in no event will the Website, the Website Owner, its and their officers, employees, agents, affiliates, licensees and web hosting services  are liable for any direct or indirect, incidental, special or consequential damages as a result of your accessing the Website and using any of the services available. Your sole remedy for any breach or default of this Agreement by the Website or the Website Owner shall be a return of any fees paid to the Website or the Website Owner for any services provided under this Agreement. You indemnify and agree to defend and hold harmless the Website, the Website Owner, its and their officers, employees, agents, affiliates, licensees and web hosting services and third parties for any losses, costs, liabilities and expenses, including but not limited to court costs, legal fees, awards or settlements. relating to or arising out of your use of the Website, including any breach by you of the Terms contained in this Agreement.',
  },
  { text: '2. Responsibility for Use of the Website.', type: 'title' },
  {
    text: 'You understand and agree that you are solely responsible for your actions and decisions to meet other people who you meet online or offline by virtue of the services provided on the Website. You understand that the Website and the Website Owner do not perform psychological testing or background checks on the individuals who may use the Website services. You understand and agree that you must take all reasonable precautions before meeting others through the services provided by the Website, which includes, but is not limited to, exchanging emails first  before giving out any personal information and meeting in a public place.',
  },
  {
    text: '3. SERVICES ARE FOR ENTERTAINMENT PURPOSES ONLY. DO NOT RELY ON THE WEBSITE.',
    type: 'title',
  },
  {
    text: 'Opinions, advice, statements, or other comments made on the Website or made as a result of services  provided by  the Website should not be relied upon and are not to be construed as professional, medical, or any other advice from the Website or the Website Owner. The Website and the Website Owner do not guarantee the accuracy or completeness of any of the information provided through the access of any of its services and are not responsible for any loss resulting from your reliance on such information.',
  },
  { text: '4. Rules of Behavior.', type: 'title' },
  { text: 'You agree to the following rules of behavior:' },
  {
    text: '',
    defaultList: [
      'You are solely responsible for your own communications and content, including any data, images, graphics, text, audio, video, email, links, and screen names you upload or post to the Website. You agree to use the Website or the Website’s services in a manner that are legal and proper.',
      'You must not abuse, defame, harass, stalk, threaten, intimidate, or violate the legal rights of others.',
      'You must not violate the privacy or publicity rights of others.',
      'You must not upload, post or link to any material that is inappropriate, defamatory, infringing, obscene, vulgar, libelous, racially or ethnically hateful, objectionable, or unlawful (or promote or provide instructional information about illegal activities or communications that could reasonably cause damage, loss, harm, or injury to any individual or group).',
      'You must not post, upload or link to (a) anything that promotes or distributes pyramid schemes or chain letters, or (b) other disruptive, illegal or immoral communications of any kind.',
      'You must not post, upload or link to anything that could potentially exploit or harm children by exposing them to inappropriate content, asking for personally identifiable details or information, or otherwise exposing them to materials or information which in any way could cause damage, injury, or harm of any kind.',
      'You must not post, upload, or link to anything that harvests or otherwise collects information about other users, including without limitation addresses, phone numbers, e-mail addresses, or health or financial information of any kind, without the users’ prior express consent in each instance.',
      'You must not post, upload, or link to anything that advertises any commercial endeavor (e.g., offering for sale any products or services) or otherwise engages in any commercial activity (e.g., conducting raffles or contests, displaying sponsorship banners, or soliciting goods or services), or solicits funds, advertisers or sponsors for any purpose.',
      'You must not upload or post anything that infringes any patent, trademark, copyright, trade secret or other intellectual property or other legal right of a third party without prior written permission from the third party in each instance, which written permission you agree to disclose to the Website Owner at any time upon request (subject to all applicable laws). You must not intentionally download any material that you know or should reasonably know cannot be distributed legally.',
      'You must not impersonate another person, group of people, or entity at any times, which includes not using anyone else’s username or password.',
      'You must not use the Website’s services for any illegal, immoral, or unauthorized purpose.',
      'You must abide by all applicable Federal, State, and local laws. If you are outside of the United States, you must comply with all local laws as well with respect to your online conduct and acceptable content including without limitation the exportation of data to the United States or to your country or residence.',
      'You cannot modify, edit, or delete any content and communications of the Website.',
      'You must not transmit any viruses, worms, defects, Trojan Horses, or any items or computer code, files or programs of a destructive nature or otherwise designed to interrupt or limit the functionality of any computer software or hardware or telecommunications device.',
      'You must not transmit or allow access or distribution of any spiders, robots, or other information gathering computer programs or routines or their equivalents on or in the Website.',
      'You must not do anything that disrupts the normal flow of dialogue, causes a screen to scroll faster than other users are able to type, or otherwise act in a way which affects the ability of other people to engage in real-time activities through the Website.',
      'You must not interfere with or disrupt the service or servers or networks connected with the Website or the Website Owner or disobey any requirements, procedures, policies. Or regulations of networks connected to the Website.',
      'You cannot create member accounts under any false or fraudulent pretenses (including by automated means).',
      'You must not state or imply that any of your submitted and or posted content is endorsed by the Website, the Website Owner, or any affiliate thereof.',
      'You must not retrieve, store, or collect personal information about any user for any unauthorized purpose, and may not retrieve, store, or collect personal information about any user for any valid purpose without such user’s prior written consent in each instance (and acknowledgment that they are at least 13 years of age).',
      'You must not engage in any “spamming” of any kind, including without limitation ad spamming.',
      'You cannot use either the Website Owner or the Website’s name, trademarks or related trade names, which you acknowledge here to be valid, subsisting and enforceable without impediment, and you will not expressly or by implication create the false or misleading impression that the Website is associated with, or endorses, or is in any way connected with you, your business, or your content.',
    ],
  },
  {
    text: 'WHILE THE WEBSITE OWNER EXPLICITLY PROHIBITS THE ABOVE CONDUCT YOU ACKNOWLEDGE AND AGREE THAT YOU MAY BE EXPOSED TO SUCH CONDUCT AND CONTENT AND THAT YOU USE THE SERVICE ENTIRELY AT YOUR OWN RISK, AND THAT THE WEBSITE OWNER SHALL HAVE NO LIABILITY OF ANY KIND FOR SUCH CONDUCT AS CARRIED OUT BY ANY THIRD PARTY AT ANY TIME UNLESS EXPRESSLY SET FORTH TO THE CONTRARY BY THE WEBSITE OWNER IN WRITING.',
    type: 'warning',
  },
  {
    text: 'YOU UNDERSTAND AND AGREE THAT IF YOU VIOLATE ANY OF THE RULES OF BEHAVIOR, THE WEBSITE OWNER CAN TERMINATE YOUR USE OF THE SERVICE IMMEDIATELY WITHOUT PRIOR NOTICE AND WITHOUT ANY RIGHT OF REFUND, SET-OFF, OR A HEARING.',
    type: 'warning',
  },
  { text: '5. Warning re: Offensive Information.', type: 'title' },
  {
    text: 'It is possible that other Website clients will send obscene or offensive materials to the Website or to you via any electronic medium. It is also possible that by using your e-mail address, other people may, through other means outside the control of the Website, have access to personal information about you. Website, Website Owner, its employees, agents, affiliates, licensees, and web hosting services are not responsible for these transmissions or for the release of such information by others about you.',
  },
  { text: '6. Right to Monitor.', type: 'title' },
  {
    text: 'The Website and the Website Owner reserve the right  to monitor materials posted in any public area and shall have the right to remove any information deemed offensive by our staff. Notwithstanding the foregoing, you remain solely responsible for your use of any information contained on the site.',
  },
  { text: '7. Confidentiality.', type: 'title' },
  {
    text: 'It is agreed that all personal information given to the Website or the Website Owner, will be kept confidential by the Website and the Website Owner, with the following exceptions: (1) basic biographical information (i.e. age, sex, race) will be posted with your user ID for others to view; (2) you actively chose to post your personal information for others to see; (3) you send a message to another client, which will disclose your e-mail address to that client; or (4) you have harassed another person via any service provided by the Website, in which case the confidentiality clause contained in this Agreement is rendered null and void.',
  },
  { text: '8. Ownership, Copyrights, Trademarks, Licenses.', type: 'title' },
  {
    text: 'The Website and the Website Owner own and retain all proprietary rights to the Website service, its trademarks, and its copyrights. Except for any information that is in the public domain, you are not authorized to reproduce, transmit, or distribute the proprietary information of the Website and the Website Owner. By posting information to the Website and the Website Owner you represent that you have the right to grant permission for use by the Website and the Website Owner.',
  },
  { text: '9. No Warranties.', type: 'title' },
  {
    text: 'The Website and the Website Owner provide the Website services on an “as is” basis and do not make any warranty, express, implied, limited, or other with respect to the services provided. Specifically, the Website and the Website Owner do not warrant that the service will always be available, be uninterrupted, be error free, meet your requirements, or that any defects in the services will be corrected.',
  },
  { text: '10. Jurisdiction.', type: 'title' },
  {
    text: 'This Agreement or any dispute arising from this Agreement is governed by the laws of the State of New York, without regard to provisions of conflicts of law. Any lawsuit arising from or related to this Agreement shall be brought exclusively before the United States District Court for the Southern District of New York and you hereby consent to the jurisdiction of any such court.',
  },
  { text: '11. Severability.', type: 'title' },
  {
    text: 'If any provision is found to be invalid, the remaining provisions will be in full force and effect.',
  },
  { text: '12. Certification.', type: 'title' },
  {
    text: 'You certify that you are at least 13 years of age and that your answers to the registration materials on the Website will be truthful. In accessing and using the Website, you understand and agree that basic information concerning you is given to the Website. Your name, address, and e-mail address are kept confidential, except in the situations provided  for above.',
  },
  { text: '13. Entire Agreement.', type: 'title' },
  {
    text: 'This Agreement constitutes your entire Agreement with the Website and the Website Owner with respect to any services.',
  },
  { text: '14. Waiver.', type: 'title' },
  {
    text: 'The failure of the Website or the Website Owner to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. Any waiver of this Agreement by the Website or the Website Owner must be in writing and signed by an authorized representative of the Website Owner.',
  },
  {
    text: 'You further understand and agree that clicking or pressing on the “agree” is the electronic equivalent of a written signature on this document.',
  },
];

export const TermsAndConditionsPage = () => {
  return (
    <PageWrapper>
      <PrivacyTextContent blocks={blocks} title="Terms & Conditions" />
    </PageWrapper>
  );
};
