import { makeStyles } from '@material-ui/core';
import { footerFacebookIconSvg, footerInstagramIconSvg, tiktokIconSvg } from '../../img/socials';

const useStyles = makeStyles(() => ({
  root: {
    padding: '35px 45px',
    backgroundColor: '#F4F7FF',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 15,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 400,
  },
  socials: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  social: {
    width: 20,
    maxHeight: 20,
    marginRight: 10,
    filter: 'invert(100%)', // invert icon white to black
    display: 'flex',
  },
}));

const paragraphs = [
  `For the best Gleen video your subject facing forward in the center of the frame.`,
  `The subject's face should be the prominent focus filling at least 30% of the video frame`,
  `Gleenr tracks small facial movements so be sure the subject's face is visible with no mask or other obstructions.`,
  `Gleenr can detect 3 people at once, however, for the best results focus on the main subject when recording.
   Filming during daylight or with additional lighting is best.`,
  `Your video should be a minimum of 10 seconds and a maximum of 30 seconds for a good gleen.
   The video resolution should be at least 480 x 370 px 24 frames.
   The video file can't exceed 100 megabytes.`,
  `To achieve the best results use original source videos.
   Videos can be uploaded from YouTube or in mp4, mov, avi, & wav formats.`,
];
const paragraphs_social_notes = [
  // `tiktok instagram notes`,
  `Gleaning videos from TikTok and Instagram can produce results with error due to their platform processing algorithms.
   In other words, it's them, not us!
   We're working hard to update these issues so please be patient with us.`
];

export const CatalogHint = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>For Best Results</div>
      {paragraphs.map((p, i) => (
        <div key={i} className={classes.paragraph}>
          {p}
        </div>
      ))}
      <div className={classes.socials}>
        <img className={classes.social} src={tiktokIconSvg} alt="" />
        <img className={classes.social} src={footerInstagramIconSvg} alt="" />
        notes
      </div>
      {paragraphs_social_notes.map((p, i) => (
        <div key={i} className={classes.paragraph}>
          {p}
        </div>
      ))}
    </div>
  );
};
