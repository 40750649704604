import { makeStyles } from '@material-ui/core';
import React from 'react';
import cn from 'classnames';
import { mobileMedia } from '../../theme';
import { PrivacyTitle } from '.';

const useStyles = makeStyles(() => ({
  wrapper: {
    fontSize: 16,
    color: '#000',
    [mobileMedia]: {
      padding: '0px 23px',
    },
  },
  textBlock: {
    marginBottom: 10,
    fontWeight: 300,
  },
  blockTitle: {
    marginTop: 10,
    fontWeight: 400,
  },
  postTitle: {
    fontSize: 12,
    fontWeight: 700,
  },
  namedListItem: {
    display: 'flex',
    alignItems: 'flex-start',
    '& span:first-child': {
      marginRight: 10,
      width: 20,
    },
    marginBottom: 10,
    fontWeight: 300,
  },
  warningText: {
    fontWeight: 500,
  },
  defaultList: {
    paddingLeft: 15,
    '& li': {},
    fontWeight: 300,
    listStyle: 'inside',
  },
}));

export type PrivacyBlock = {
  text: string;
  namedList?: {
    [key: string]: string;
  };
  defaultList?: Array<string>;
  type?: 'title' | 'postTitle' | 'warning';
};

type Props = {
  blocks: Array<PrivacyBlock>;
  title: string;
};

export const TextContent: React.FC<Props> = ({ blocks, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <PrivacyTitle text={title} />

      {blocks.map((b, index) => {
        if (b.namedList) {
          const listOptions: Array<JSX.Element> = Object.keys(b.namedList).map((k, i) => (
            <div key={i} className={classes.namedListItem}>
              <span>{k}</span>
              <span>{b.namedList[k]}</span>
            </div>
          ));

          return listOptions;
        }

        if (b.defaultList) {
          return (
            <ul key={index} className={classes.defaultList}>
              {b.defaultList.map((item, i) => (
                <li className={classes.textBlock} key={i}>
                  {item}
                </li>
              ))}
            </ul>
          );
        }

        return (
          <p
            key={index}
            className={cn(classes.textBlock, {
              [classes.blockTitle]: b.type === 'title',
              [classes.postTitle]: b.type === 'postTitle',
              [classes.warningText]: b.type === 'warning',
            })}
          >
            {b.text}
          </p>
        );
      })}
    </div>
  );
};
