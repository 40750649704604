import toast from 'react-hot-toast';

interface IMakeToast {
  message: string;
  type: 'success' | 'error' | 'default';
}

export const makeToast = ({ message, type }: IMakeToast) => {
  let mainColor = 'grey';

  if (type === 'error') {
    mainColor = '#F27256';
  }

  if (type === 'success') {
    mainColor = '#9FCD63';
  }

  return (type === 'default' ? toast : toast[type])(message, {
    duration: 3000,
    position: 'top-right',
    // Styling
    style: {
      borderLeft: `6px solid ${mainColor}`,
      color: '#FFFFFF',
      backgroundColor: '#222527',
      padding: '12px 19px',
    },
    icon: (
      <svg
        width="50"
        height="50"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M12.1793 14C12.1831 14 12.1867 14 12.1904 14C13.0216 14 13.8031 13.6772 14.392 13.0903C14.9836 12.5007 15.3094 11.7161 15.3094 10.8809V6.09521H11.2711C10.0581 6.09521 9.07129 7.08202 9.07129 8.295V10.851C9.07129 12.5814 10.4656 13.994 12.1793 14Z"
              fill={mainColor}
            />
            <path
              d="M17.3158 14.6449C16.6907 14.0159 15.8581 13.6661 14.9716 13.6601L14.8345 13.6592L14.732 13.7502C14.0322 14.3718 13.1296 14.714 12.1911 14.714H12.1775C11.2524 14.7108 10.359 14.3684 9.66191 13.7499L9.5601 13.6596L9.424 13.6599C7.58848 13.6645 6.09521 15.1615 6.09521 16.997V18.2855H18.2857V16.997C18.2857 16.1096 17.9412 15.2743 17.3158 14.6449Z"
              fill={mainColor}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="12.1905"
                height="12.1905"
                fill="white"
                transform="translate(6.09521 6.09521)"
              />
            </clipPath>
          </defs>
        </svg>
      </svg>
    ),
  });
};
