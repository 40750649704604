// modules
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// types
import { DataState } from './types';
import { Data } from '../components/PulseChart/types';

const initialState: DataState = {
  data: [],
  dataMeanSincerity: {},
  currentFrame: 0,
  bloodPressure: '',
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setData(state: DataState, action: PayloadAction<Data[]>) {
      state.data = action.payload;
    },
    setDataMeanSincerity(state: DataState, action: PayloadAction<{}>) {
      state.dataMeanSincerity = action.payload;
    },
    setCurrentFrame(state: DataState, action: PayloadAction<number>) {
      state.currentFrame = action.payload;
    },
    setBloodPressure(state: DataState, action: PayloadAction<number | string>) {
      state.bloodPressure = action.payload;
    },
  },
});

export const { setData, setDataMeanSincerity, setCurrentFrame, setBloodPressure } = dataSlice.actions;
export default dataSlice.reducer;
