const getEyesValue = (data: string, t: any) : string => {
  if (data) {
    if (data.toLowerCase() === 'vr') {
      return t('recall');
    } else if (data.toLowerCase() === 'ar') {
      return t('recall');
    } else if (data.toLowerCase() === 'vc') {
      return t('construction');
    } else if (data.toLowerCase() === 'ac') {
      return t('construction');
    } else if (data.toLowerCase() === 'ad') {
      return t('sensing');
    } else if (data.toLowerCase() === 'k') {
      return t('sensing');
    } else if (data.toLowerCase() === 'c') {
      return t('visualization');
    }
  } else {
    return null;
  }
};

export default getEyesValue;
