import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../Navbar';
import { logoSvg } from '../../img';
import { mobileMedia } from '../../theme';

const useStyles = makeStyles(() => ({
  root: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000',
    [mobileMedia]: {
      padding: '21px 23px',
      paddingRight: 27,
    },
  },
  logo: {
    [mobileMedia]: {
      margin: '0 auto',
    },
  },
}));

type Props = {
  hiddenOnDesktop?: boolean;
};

const Header: React.FC<Props> = ({ hiddenOnDesktop }) => {
  const classes = useStyles();

  if (hiddenOnDesktop) {
    return <Navbar hiddenOnDesktop />;
  }

  return (
    <header className={classes.root}>
      <img src={logoSvg} alt="" className={classes.logo} />
      <Navbar />
    </header>
  );
};

export default Header;
