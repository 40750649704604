import { capitalize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataBlock } from './DataBlock';
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis } from 'recharts';
import { calculateEmotionTotalValue, uniquePieData } from '../../pages/report';

type Props = {
  data: [number, string][];
};

const getDataByKey = (key: string) => {
  if (key === 'r') {
    return 'remembrance';
  }

  if (key === 'c') {
    return 'construction';
  }

  return key;
};

export const EyeBlock: React.FC<Props> = ({ data: initialData }) => {
  const { t } = useTranslation();

  const data: [number, string][] = initialData
    .filter((d) => d[1] === 'r' || d[1] === 'c')
    .map((d) => [d[0], getDataByKey(d[1])]);

  const colors = {
    recall: '#6081f0',
    construction: '#040E9A',
    '': '#4D77FF',
  };

  const lineData = data.map((d) => ({
    name: d[0],
    eyes: 10,
    fill: colors[d[1] as ''] || '#4D77FF',
  }));

  const pieDataValues = uniquePieData(
    data.map((d) => [capitalize(t(d[1])), calculateEmotionTotalValue(d[1], data)])
  );
  const pieData = [['type', 'Percents'], ...pieDataValues];

  const slices: any = {};
  data.forEach((d, i) => {
    slices[i] = {
      color: colors[d[1] as ''] || '#4D77FF',
    };
  });

  return (
    <DataBlock
      colorSlices={slices}
      withHorizontalScroll
      pieData={pieData}
      lineChartType="LineChart"
      lineComponent={
        <ResponsiveContainer width={'100%'} height={'85%'} className="report-area-chart-wrapper">
          <BarChart barGap={'1%'} data={lineData} barCategoryGap="0">
            <XAxis
              tickFormatter={(_, i) => {
                return lineData[i] ? String(lineData[i].name) : '';
              }}
            />
            <Bar dataKey="eyes" fill="#8884d8" radius={[15, 15, 0, 0]}>
              {lineData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fill} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      }
    />
  );
};
