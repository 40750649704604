import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import Bar from './Bar';
import {
  agressiveSvg,
  disgustSvg,
  excitementSvg,
  neutralSvg,
  satisfactionSvg,
  stressSvg,
} from '../../img';

const useStyles = makeStyles({
  root: {
    marginRight: 10,
  },
  wrapper: {
    width: '100%',
  },
  barWrapper: {
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

interface ModalProps {
  emotions: any[];
  position?: string;
  width?: number;
}

const EmotionTable: React.FC<ModalProps> = ({ emotions }) => {
  const classes = useStyles();

  const emotionsTags = [
    { name: 'Angry', icon: agressiveSvg },
    { name: 'Disgust', icon: disgustSvg },
    { name: 'Stress', icon: stressSvg },
    { name: 'Discontent', icon: disgustSvg },
    { name: 'Excitement', icon: excitementSvg },
    { name: 'Neutral', icon: neutralSvg },
    { name: 'Happy', icon: satisfactionSvg },
  ];

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.wrapper}>
        {emotionsTags.map((item, index) => (
          <div className={classes.barWrapper} key={index}>
            <Bar icon={item.icon} name={item.name} value={emotions[index] && emotions[index][1]} />
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default EmotionTable;
