import { LinearProgress, withStyles, Slider } from '@material-ui/core';

type Props = {
  height: number;
  value: number;
  color?: string;
};

export const LinearBar: React.FC<Props> = ({ height, value, color = '#9D1091' }) => {
  const Bar = withStyles(() => ({
    root: {
      height,
      borderRadius: 11,
    },
    colorPrimary: {
      backgroundColor: '#C1C1C1',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: color,
    },
  }))(LinearProgress);

  return <Bar variant="determinate" value={value} />;
};

type PropsS = {
  height: number;
  value: number;
  color?: string;
  backgroundImage?: string;
  colorlessFilter?: boolean;
  thumbStyles?: Object;
};

export const LinearBarS: React.FC<PropsS> = ({
  height,
  value,
  backgroundImage,
  color = '#9D1091',
  colorlessFilter = true,
  thumbStyles,
}) => {
  const Bar = withStyles(() => ({
    root: {
      padding: 0,
      height,
      borderRadius: 15,
    },
    colorPrimary: {
      backgroundColor: '#C1C1C1',
    },
    rail: {
      height: '0px',
      type: 'hidden',
    },
    track: {
      height,
      backgroundColor: color,
      borderRadius: '15px 0px 0px 15px',
    },
    thumb: {
      width: height * 2,
      height: height * 2,
      backgroundColor: 'white',
      marginTop: -height / 4,
      marginLeft: -height / 3,
      backgroundImage,
      backgroundSize: 'contain',
      filter: colorlessFilter ? 'grayscale(100%)' : null,
      display: 'inline-block',
      border: '1px solid ' + color,
      top: '-15%',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      ...(thumbStyles || {}),
    },
  }))(Slider);

  return <Bar value={value} />;
};
