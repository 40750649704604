const getRandomNumber = (min: number, max: number): number => {
  //The maximum is inclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomBloodPressure = (): string => {
  const firstNumber = getRandomNumber(110, 130);
  const secondNumber = getRandomNumber(70, 90);

  return `${firstNumber}/${secondNumber}`;
};

export default getRandomBloodPressure;
