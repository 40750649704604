import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { dropdownIconSvg } from '../../img';
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  graphicsTitle: {
    marginBottom: 23,
    fontWeight: 700,
    fontSize: 18,
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: 8,
    cursor: 'pointer',
  },
  dropdownImage: {
    display: 'block',
    marginLeft: 21,
  },
  dropdownImageClosed: {
    transform: 'rotate(180deg)',
  },
}));

type Props = {
  wrapperClassName: string;
  title: string;
  titleClassName?: string;
  hideDropdownBtn?: boolean;
};

export const DropdownBlock: React.FC<Props> = ({
  children,
  title,
  wrapperClassName,
  titleClassName = '',
  hideDropdownBtn = false,
}) => {
  const classes = useStyles();
  const [isDropdownOpened, setIsDropdownOpened] = useState(true);

  return (
    <div className={wrapperClassName}>
      <h3
        className={cn(classes.graphicsTitle, titleClassName)}
        onClick={() => setIsDropdownOpened((prev) => !prev)}
      >
        <span>{title}</span>
        {!hideDropdownBtn && (
          <img
            className={cn(classes.dropdownImage, {
              [classes.dropdownImageClosed]: !isDropdownOpened,
            })}
            src={dropdownIconSvg}
            alt=""
          />
        )}
      </h3>
      {isDropdownOpened && children}
    </div>
  );
};
