export const getVideoDuration = async ({ file }: { file: File }) => {
  const video = document.createElement('video');
  video.preload = 'metadata';

  video.src = URL.createObjectURL(file);

  return new Promise((res) => {
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      res(duration);
    };
  });
};
